import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../../store/store';
import InstitutionAdminDashboard from '../../../containers/InstitutionAdminDashboard/InstitutionAdminDashboard';
import { useSwrAgent } from '../../../api/useSwrAgent';
import MyButton from '../../Buttons/MyButton/MyButton';
import { twMerge } from '../../../index';
import MyDisclosure from '../../Disclosure/MyDisclosure';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import { MakeInstitutionFundOrderForm } from './MakeInstitutionFundOrderForm';
import { RemoveIFOrderButton } from './RemoveIFOrderButton';
import { IFOrderStatus } from '../../../enums/institutionFund.enums';
import agent from '../../../api/agent';
import FileHelper from '../../../helpers/FileHelper';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import SubpageHeader from '../../SubpageHeader/SubpageHeader';
import PiggyBankIcon from '../../Icons/PiggyBankIcon';
import InstitutionAdminFundRegulationsForm from './InstitutionAdminFundRegulationsForm';

const products = [
  {
    image: 'https://via.placeholder.com/400x300', // Replace with real product images
    name: 'Smartfon XYZ',
    category: 'Elektronika',
    seller: 'Tech Store',
    availability: 'Dostępny',
    pricePerUnit: '2 199 zł',
    totalPrice: '2 199 zł',
    rating: 4.8,
    reviews: 152,
  },
  {
    image: 'https://via.placeholder.com/400x300',
    name: 'Buty Sportowe ABC',
    category: 'Obuwie',
    seller: 'Sport Shop',
    availability: 'Ograniczona ilość',
    pricePerUnit: '349 zł',
    totalPrice: '349 zł',
    rating: 4.7,
    reviews: 78,
  },
  {
    image: 'https://via.placeholder.com/400x300',
    name: 'Laptop UltraBook',
    category: 'Komputery',
    seller: 'Laptop World',
    availability: 'Dostępny',
    pricePerUnit: '4 499 zł',
    totalPrice: '4 499 zł',
    rating: 4.9,
    reviews: 320,
  },
  {
    image: 'https://via.placeholder.com/400x300',
    name: 'Zegarek Smart',
    category: 'Akcesoria',
    seller: 'Gadget Store',
    availability: 'Brak w magazynie',
    pricePerUnit: '999 zł',
    totalPrice: '999 zł',
    rating: 4.6,
    reviews: 45,
  },
];

const InstitutionFundDashboard: React.FC = observer(() => {
  const { userStore, modalStore } = useStore();

  const {
    data: fundDetails,
    isLoading,
    mutate,
  } = useSwrAgent().InstitutionAdmin.GetFundDetails(userStore.currentInstId ?? 0);

  const {
    data: fundOrders,
    isLoading: areFundOrdersLoading,
    mutate: fundOrdersMutate,
  } = useSwrAgent().InstitutionAdmin.GetFundOrders(userStore.currentInstId ?? 0);
  const {
    data: hasAcceptedRegulations,
    isLoading: areHasAcceptedRegulationsLoading,
    mutate: hasAcceptedRegulationsMutate,
  } = useSwrAgent().InstitutionAdmin.HasAcceptedFundRegulationsAuthorization(userStore.currentUserInstId ?? 0);

  const instName = userStore.getInstName();

  const totalUsedAmount = fundOrders?.reduce(
    (sum, invoice) =>
      sum +
      (invoice.status === IFOrderStatus.Pending || invoice.status === IFOrderStatus.Completed
        ? invoice.grossAmount
        : 0),
    0,
  );

  const downloadInvoiceFile = (guid: string, fileName: string) => {
    agent.InstitutionAdmin.getFile(guid, userStore.currentInstId ?? 0).then((resp) => {
      FileHelper.downloadFileFromBlob(resp, fileName);
    });
  };

  return (
    <InstitutionAdminDashboard>
      <div className={'tw-bg-gray-100 tw-p-2'}>
        {(isLoading || areFundOrdersLoading || areHasAcceptedRegulationsLoading) && <LoadingComponent />}
        {!hasAcceptedRegulations && <InstitutionAdminFundRegulationsForm onSave={hasAcceptedRegulationsMutate} />}
        {hasAcceptedRegulations && (
          <div className={'tw-mx-auto tw-max-w-4xl '}>
            <div className={'tw-rounded-xl tw-bg-white'}>
              <SubpageHeader
                headerClassName={twMerge('tw-text-3xl sm:tw-text-4xl')}
                iconComponent={<PiggyBankIcon className={'sm:tw-mr-4'} />}
                title={'Fundusz'}
              />
              <div className={'tw-mb-8 tw-p-4  tw-text-xl tw-text-nau-green-dark'}>
                <div className={'tw-font-bold'}>{instName}</div>
                {fundDetails?.configurationDetails.suspended ? (
                  <div className={'tw-mt-2 tw-font-normal '}>
                    Fundusz zawieszony od: <span>{fundDetails.configurationDetails.suspensionDateFormatted}</span>
                    <div className={''}>Powód zawieszenia: {fundDetails?.configurationDetails.suspensionReason}</div>
                  </div>
                ) : (
                  <>
                    <div className={'tw-mt-2 tw-font-normal'}>
                      Całkowita suma funduszu:{' '}
                      <span className={'tw-font-bold'}>
                        {fundDetails?.configurationDetails.totalAssignedAmountInActuallyCycle || 0} zł
                      </span>
                    </div>
                    <div className={'tw-mt-2 tw-font-normal'}>
                      Aktualne saldo:{' '}
                      <span className={'tw-font-bold'}>
                        {' '}
                        {fundDetails?.configurationDetails.currentBalance || 0} zł
                      </span>{' '}
                    </div>
                    <div className={'tw-mt-2 tw-font-normal'}>
                      Data ważności środków:{' '}
                      <span className={'tw-font-bold'}>
                        {' '}
                        {fundDetails?.configurationDetails.fundsExpirationDateFormatted || '-'}
                      </span>{' '}
                    </div>
                    <div className={'tw-mt-2 tw-font-normal'}>
                      Twój % odpisu składki to:{' '}
                      <span className={'tw-font-bold'}>
                        {' '}
                        {fundDetails?.configurationDetails.actuallyCalculatedPercentage || 0}
                      </span>{' '}
                    </div>
                  </>
                )}
              </div>
            </div>

            {fundDetails?.configurationDetails.active && !fundDetails?.configurationDetails.suspended && (
              <div className={'tw-mb-8 tw-rounded-xl tw-bg-white tw-p-4  tw-text-xl'}>
                <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>Wykorzystaj fundusz na zakupy</div>
                <div className={'tw-mt-6 tw-flex tw-gap-4'}>
                  <MyButton
                    className={''}
                    onClick={() =>
                      modalStore.openModal(
                        <MakeInstitutionFundOrderForm
                          institutionId={userStore.currentInstId ?? 0}
                          onSave={() => {
                            modalStore.closeModal();
                            mutate();
                            fundOrdersMutate();
                          }}
                        />,
                        'lg',
                        'Wykorzystaj fundusz na zakupy',
                      )
                    }
                    variant={'primary'}>
                    Skorzystaj
                  </MyButton>
                </div>
              </div>
            )}

            {fundOrders && fundOrders.length > 0 && (
              <div className={'tw-rounded-xl tw-bg-white  '}>
                <div className='tw-mb-4 tw-p-4  tw-text-lg tw-text-nau-green-dark'>
                  Do tej pory wykorzystano z funduszu: <span className={'tw-font-bold'}> {totalUsedAmount} zł</span>
                </div>
                <MyDisclosure
                  defaultOpen={true}
                  header={(open) => (
                    <div className={twMerge('tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-4 tw-pb-4')}>
                      <div className='tw-flex  tw-items-center '>
                        <span className='tw-text-xl tw-font-semibold tw-text-nau-green-dark'>Sprawdź faktury</span>
                      </div>
                      <div className='tw-grow-1 tw-flex tw-items-center tw-justify-between  tw-gap-2 tw-text-sm sm:tw-grow-0 '>
                        <h2 className='tw-flex tw-items-center tw-justify-between tw-text-left tw-text-lg tw-font-bold tw-text-white'>
                          {open ? 'Ukryj' : 'Pokaż'} faktury
                        </h2>
                        <ArrowDownCircle
                          className={twMerge(
                            'tw-h-8 tw-w-8 tw-text-nau-green-dark tw-transition-all',
                            open && 'tw-rotate-180',
                          )}
                        />
                      </div>
                    </div>
                  )}
                  wrapperClassName='tw-rounded-lg'>
                  <div className='tw-rounded-b-lg   tw-py-4'>
                    <div className={'tw-flex tw-flex-col tw-divide-y tw-divide-gray-300 tw-text-nau-green-dark'}>
                      {fundOrders?.map((order) => (
                        <div className=' tw-relative tw-px-4 tw-py-4 tw-text-base even:tw-bg-gray-50' key={order.id}>
                          {order.status === IFOrderStatus.Pending && (
                            <div
                              className={
                                'tw-absolute tw-right-2 tw-top-2 tw-cursor-pointer tw-transition-all hover:tw-scale-110'
                              }>
                              <RemoveIFOrderButton
                                ifOrderId={order.id}
                                institutionId={userStore.currentInstId ?? 0}
                                onSave={() => {
                                  mutate();
                                  fundOrdersMutate();
                                }}
                              />
                            </div>
                          )}

                          <p className='tw-mb-1 tw-font-bold'>Faktura: {order.invoiceNumber}</p>
                          <p className='tw-mb-1 '>{order.paymentTypeName}</p>
                          <p className={'tw-mb-1 tw-flex tw-gap-2'}>
                            Status:{' '}
                            <div
                              className={twMerge(
                                'tw-h-fit tw-w-fit tw-rounded-2xl tw-bg-nau-green-light tw-px-2 tw-py-1 tw-text-xs tw-font-bold tw-text-white',
                                order.status === IFOrderStatus.Realized
                                  ? 'tw-bg-green-500'
                                  : order.status === IFOrderStatus.Pending
                                    ? 'tw-bg-yellow-500 '
                                    : 'tw-bg-rose-500 ',
                              )}>
                              {order.statusName}
                            </div>
                          </p>
                          {order.comment && <p className={'tw-mb-1'}>Komentarz do statusu: {order.comment}</p>}
                          <p className={'tw-mb-1'}>Kwota: {order.grossAmount} zł</p>
                          <p className={'tw-mb-1'}>Data: {order.orderDateFormatted}</p>
                          <p className={'tw-mb-1'}>
                            Zamawiający: {order.orderedByFirstName + ' ' + order.orderedByLastName}
                          </p>
                          <div className={'mt-2 tw-font-bold'}>Załączone pliki</div>
                          {order.files.map((file) => (
                            <p
                              className={'cursor-pointer tw-italic tw-text-nau-green-dark'}
                              key={file.id}
                              onClick={() => downloadInvoiceFile(file.fileGuid, file.fileNameWithExt)}>
                              {file.fileNameWithExt}
                            </p>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </MyDisclosure>
              </div>
            )}

            <div className='tw-px-4 tw-py-8'>
              {/* <h2 className='tw-mb-4 tw-mt-8 tw-text-2xl tw-font-bold'>Produkty</h2> */}
              {/* <p className='tw-mb-8 tw-text-gray-500'> */}
              {/*   Przeglądaj nasze popularne produkty i znajdź produkt idealny dla Twojej szkoły. */}
              {/* </p> */}
              {/* <div className='tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4'> */}
              {/*   {products.map((product, index) => ( */}
              {/*     <ProductTile key={index} {...product} /> */}
              {/*   ))} */}
              {/* </div> */}
              {/* <button */}
              {/*   className='tw-hover:bg-gray-200 tw-mt-6 tw-rounded-md tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-gray-800 tw-shadow-sm'> */}
              {/*   Dowiedz się więcej */}
              {/* </button> */}
            </div>
          </div>
        )}
      </div>
    </InstitutionAdminDashboard>
  );
});

export default InstitutionFundDashboard;
