function formatDateQueryParam(key: string, value: any): string {
  if (value instanceof Date) {
    const isoDateString = value.toISOString();
    return `${key}=${encodeURIComponent(isoDateString)}`;
  }
  throw new Error(`Invalid date value for key ${key}`);
}

function formatQueryParam(key: string, value: any): string {
  return `${key}=${encodeURIComponent(value.toString().trim())}`;
}

export function extractQueryParams(searchModel: Record<string, any> | undefined): string {
  if (!searchModel) {
    return '';
  }

  return Object.entries(searchModel)
    .filter(([_, value]) => value !== undefined)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        // Handle array values
        return value.map((item) => formatQueryParam(key, item));
      } else if (value instanceof Date) {
        // Handle Date values
        return formatDateQueryParam(key, value);
      } else {
        // Handle single values
        return formatQueryParam(key, value);
      }
    })
    .join('&');
}
