import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useStore } from '../../../store/store';
import InstitutionAdminDashboard from '../../../containers/InstitutionAdminDashboard/InstitutionAdminDashboard';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { twMerge } from '../../../index';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import SubpageHeader from '../../SubpageHeader/SubpageHeader';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { Button } from 'react-bootstrap';
import BuildingIcon from '../../Icons/BuildingIcon';
import DatePicker from 'react-datepicker';
import MyDisclosure from '../../Disclosure/MyDisclosure';
import { ArrowDownCircle, HandThumbsUp } from 'react-bootstrap-icons';
import MyButton from '../../Buttons/MyButton/MyButton';
import { InstitutionAdminSettlementsDetails } from './InstitutionAdminSettlementsDetails';
import agent from '../../../api/agent';

export interface InstitutionSettlementFromSop {
  id: number;
  institutionId: number;
  institutionName: string;
  amount: number;
  paymentAmount: number;
  month: Date;
  settled: boolean;
  isSent: boolean;
  statusId: number;
  statusName: string;
  monthFormatted: string;
  amountFormatted: string;
  paymentAmountFormatted: string;
  balance: number;
  balanceFormatted: string;
}

const InstitutionFundDashboard: React.FC = observer(() => {
  const { userStore, modalStore, paymentsStore } = useStore();

  const {
    data: institutionSettlements,
    isLoading: areInstitutionSettlementsLoading,
    mutate: mutateInstitutionSettlements,
  } = useSwrAgent().InstitutionAdmin.GetInstitutionSettlements(userStore.currentInstId ?? 0);

  const instName = userStore.getInstName();

  const [selectedMonth, setSelectedMonth] = useState<Date | null>(null); // e.g. "2025-02"

  const handleMonthChange = (date: Date | null) => {
    setSelectedMonth(date);
  };
  const createAutopayLink = async (settlementId?: number) => {
    if (!settlementId || !userStore.currentInstId) {
      return;
    }
    const productSettlementIds = await agent.InstitutionAdmin.getProductSettlementsToPayForSettlement(
      settlementId,
      userStore.currentInstId,
    );
    const settlementIds = [settlementId];
    paymentsStore.createAutopayLinkInSop(settlementIds, productSettlementIds).then((response) => {
      if (response) {
        window.location.href = response;
      }
    });
  };

  const filteredData = useMemo(() => {
    if (!selectedMonth) return institutionSettlements;

    return institutionSettlements?.filter((item) => {
      const date = new Date(item.month);

      // const itemDate = dayjs(item.formattedDate, 'MM.yyyy');
      return date.getMonth() === selectedMonth.getMonth() && date.getFullYear() === selectedMonth.getFullYear();
      // return itemDate.month() === dayjs(selectedMonth).month() && itemDate.year() === dayjs(selectedMonth).year();
    });
  }, [selectedMonth, institutionSettlements]);

  const openSettlementsDetails = (item: InstitutionSettlementFromSop) => {
    modalStore.openModal(
      <InstitutionAdminSettlementsDetails
        institutionId={userStore.currentInstId ?? 0}
        onSave={() => {
          modalStore.closeModal();
        }}
        settlementId={item.id}
      />,
      'lg',
      'Szczegóły rozliczenia',
    );
  };

  return (
    <InstitutionAdminDashboard>
      <div className={'tw-bg-gray-100 tw-p-2'}>
        {areInstitutionSettlementsLoading && <LoadingComponent />}
        <div className={'tw-mx-auto tw-max-w-3xl '}>
          <div className={'tw-rounded-xl tw-bg-white'}>
            <SubpageHeader
              headerClassName={twMerge('tw-text-3xl sm:tw-text-4xl')}
              iconComponent={<BanknotesIcon className={'tw-h-12 tw-w-12 sm:tw-mr-4'} />}
              title={'Rozliczenia'}
            />

            <div className={'tw-mb-8  tw-text-xl tw-text-nau-green-dark'}>
              <div
                className={
                  'tw-mb-8 tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-nau-green-dark tw-px-2 tw-py-4 tw-font-bold'
                }>
                <BuildingIcon className={'tw-h-12'} />
                <div>
                  <div className={'tw-text-xl'}>{instName}</div>
                  <div className={'tw-text-base tw-font-normal'}>Regon: 123123123</div>
                </div>
              </div>
              <div className={'tw-mb-4 tw-w-full tw-text-center tw-text-2xl tw-font-semibold'}>Wykaz miesięcy</div>
              <div className={'tw-px-2'}>
                <MyDisclosure
                  defaultOpen={false}
                  header={(open) => (
                    <div
                      className={twMerge(
                        'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-4 tw-py-2',
                        open && 'tw-border-b ',
                      )}>
                      <div className='tw-flex  tw-items-center '>
                        <span className='tw-text-xl tw-font-semibold tw-text-nau-green-dark'>Filtry</span>
                      </div>
                      <div className='tw-grow-1 tw-flex tw-items-center tw-justify-between  tw-gap-2 tw-text-sm sm:tw-grow-0 '>
                        <ArrowDownCircle
                          className={twMerge(
                            'tw-h-8 tw-w-8 tw-text-nau-green-dark tw-transition-all',
                            open && 'tw-rotate-180',
                          )}
                        />
                      </div>
                    </div>
                  )}
                  wrapperClassName='tw-border tw-rounded-lg'>
                  <div className='  tw-px-4 tw-py-4'>
                    <div className={'tw-flex tw-items-center  tw-gap-2'}>
                      <div className={''}>
                        <div className='tw-mb-0.5 tw-text-sm tw-font-semibold'>Miesiąc:</div>
                        <DatePicker
                          className={'tw-rounded-lg'}
                          clearButtonClassName={'tw-text-nau-green-dark tw-font-semibold'}
                          dateFormat='MM.yyyy'
                          isClearable={true}
                          locale='pl'
                          onChange={handleMonthChange}
                          placeholderText={'filtruj...'}
                          selected={selectedMonth}
                          showMonthYearPicker={true}
                        />
                      </div>
                    </div>

                    <div className={'tw-mt-2 tw-flex tw-w-full tw-justify-end'}>
                      {selectedMonth && (
                        <MyButton
                          className={'tw-py-0.5'}
                          onClick={() => {
                            setSelectedMonth(null);
                          }}
                          variant={'gray'}>
                          Wyczyść filtry
                        </MyButton>
                      )}
                    </div>
                  </div>
                </MyDisclosure>
              </div>

              <div>
                <div className='tw-rounded-b-lg   tw-py-4'>
                  <div className={'tw-flex tw-flex-col tw-divide-y tw-divide-gray-300 tw-text-nau-green-dark'}>
                    {filteredData?.map((item: InstitutionSettlementFromSop) => (
                      <div className='tw-flex tw-flex-col tw-gap-2 tw-p-4 even:tw-bg-gray-50 ' key={item.id}>
                        {/* Month (title) */}
                        <h2 className='tw-mb-2 tw-flex tw-items-center tw-justify-between tw-text-xl tw-font-semibold'>
                          {item.monthFormatted}

                          {item.balance < 0 && (
                            <div className='tw-text-xl tw-font-bold tw-text-red-600'>
                              Do zapłaty: {Math.abs(item.balance)} zł
                            </div>
                          )}

                          {item.balance >= 0 && (
                            <div className='tw-flex tw-items-center tw-gap-2 tw-text-xl tw-font-bold tw-text-nau-green-light'>
                              <div>Uregulowano</div>
                              <HandThumbsUp className={'tw-h-12 tw-w-12 tw-text-nau-green-light'} />
                            </div>
                          )}
                        </h2>

                        {item.balance < 0 && (
                          <Button
                            className='tw-mb-2 tw-whitespace-nowrap '
                            onClick={() => {
                              createAutopayLink(item.id);
                            }}
                            variant={'primary'}>
                            <div className={'tw-flex tw-items-center tw-justify-center'}>
                              <BanknotesIcon className={'tw-mr-2 tw-h-6 tw-w-5'} />
                              Zapłać online
                            </div>
                          </Button>
                        )}

                        {/* Required amount */}
                        <div className='tw-text-base'>
                          <span className='tw-text-gray-600'>Kwota wymagana: </span>
                          <span className={'tw-font-semibold'}> {item.amountFormatted}</span>
                        </div>

                        {/* Transfer to NAU */}
                        <div className='tw-text-base'>
                          <span className='tw-text-gray-600'>Zapłacono: </span>
                          <span className={'tw-font-semibold'}> {item.paymentAmountFormatted}</span>
                        </div>

                        {/* Settlement status */}
                        <div className='tw-flex tw-gap-2 tw-text-base'>
                          <div className='tw-text-gray-600'>Status rozliczenia: </div>
                          <div
                            className={twMerge(
                              'tw-h-fit tw-w-fit tw-rounded-2xl tw-bg-nau-green-light tw-px-2 tw-py-1 tw-text-xs tw-font-bold tw-text-white',
                              'tw-bg-green-500',
                              item.statusName === 'Nieopłacone' && 'tw-bg-rose-500',
                              item.statusName === 'Wpłata zbyt mała' && 'tw-bg-teal-500',
                            )}>
                            {item.statusName}
                          </div>
                        </div>

                        <div className={'tw-flex tw-justify-end'}>
                          <MyButton
                            className=' tw-whitespace-nowrap '
                            onClick={() => {
                              openSettlementsDetails(item);
                            }}
                            variant={'greenDark'}>
                            Szczegóły rozliczenia
                          </MyButton>
                        </div>
                      </div>
                    ))}
                    {!filteredData?.length && (
                      <div className={'tw-mt-4 tw-w-full tw-text-center tw-text-gray-500'}>
                        Brak wyników spełniających kryteria
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InstitutionAdminDashboard>
  );
});

export default InstitutionFundDashboard;
