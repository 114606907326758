import React, { useState } from 'react';
import { InstitutionSettlementPerEmployeeDto } from '../../../types/institutionSettlements';
import { AtSymbolIcon, IdentificationIcon, PhoneIcon } from '@heroicons/react/24/outline';
import MyDisclosure from '../../Disclosure/MyDisclosure';
import { twMerge } from '../../../index';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingContainer from '../../Loadings/LoadingContainer/LoadingContainer';
import { InstitutionAdminEmployeeProductSettlements } from './InstitutionAdminEmployeeProductSettlements';

interface IInstitutionAdminSettlementsDetailsProps {
  settlementId: number;
  institutionId: number;
  onSave?: () => void;
}

export function InstitutionAdminSettlementPerEmployeeDetails(props: IInstitutionAdminSettlementsDetailsProps) {
  const [institutionSettlements, setInstitutionSettlements] = useState<InstitutionSettlementPerEmployeeDto[]>([]);

  const {
    data: employeeSettlements,
    isLoading,
    mutate,
  } = useSwrAgent().InstitutionAdmin.GetEmployeeSettlementsForSettlement(props.settlementId, props.institutionId);

  return (
    <div className={'tw-relative tw-py-4'}>
      {isLoading && <LoadingContainer />}
      <div className={'tw-mb-8 tw-flex tw-grow tw-justify-between '}>
        <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>
          Lista pracowników wraz z ich rozliczeniami
        </div>
      </div>
      <div className='tw-grid tw-grid-cols-1 tw-gap-6 '>
        {employeeSettlements?.map((employee) => (
          <div
            className='tw-flex tw-flex-col tw-gap-2 tw-rounded-lg tw-border tw-bg-white tw-shadow'
            key={employee.employeeId}>
            {/* Employee name */}
            <div className='tw-text-nau-text-1 tw-w-full tw-rounded-t-lg tw-px-2 tw-py-2 tw-text-lg tw-font-semibold'>
              {employee.firstName} {employee.lastName}
            </div>

            <div className={'tw-p-2'}>
              <div className={'tw-flex tw-w-full'}>
                <div className={'tw-flex tw-flex-col tw-gap-1'}>
                  {/* Pesel */}
                  {employee.pesel && (
                    <div className={'tw-flex tw-items-center tw-gap-2'}>
                      <IdentificationIcon className={'tw-inline-block tw-h-5 tw-text-gray-500'} />
                      <div className='tw-font-semibold'>{employee.pesel}</div>
                    </div>
                  )}

                  {/* Email */}
                  {employee.email && (
                    <div className={'tw-flex tw-items-center tw-gap-2'}>
                      <AtSymbolIcon className={'tw-inline-block tw-h-5 tw-text-gray-500'} />
                      <span className='tw-font-semibold'>{employee.email}</span>
                    </div>
                  )}

                  {/* Phone */}
                  {employee.phoneNumber && (
                    <div className={'tw-flex tw-items-center tw-gap-2'}>
                      <PhoneIcon className={'tw-inline-block tw-h-5 tw-text-gray-500'} />
                      <span className='tw-font-semibold'>{employee.phoneNumber}</span>
                    </div>
                  )}
                </div>
                <div className={'tw-flex tw-grow tw-justify-end'}>
                  <div className={'tw-text-xl tw-font-bold tw-text-nau-green-light'}>{employee.amountFormatted}</div>
                </div>
              </div>
              <div className={'tw-mt-4'}>
                <MyDisclosure
                  defaultOpen={false}
                  header={(open) => (
                    <div
                      className={twMerge(
                        'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-2 tw-py-1',
                        open && 'tw-border-b ',
                      )}>
                      <div className='tw-flex  tw-items-center '>
                        <span className='tw-text-base tw-font-semibold tw-text-nau-green-dark'>
                          Szczegóły per produkt
                        </span>
                      </div>
                      <div className='tw-grow-1 tw-flex tw-items-center tw-justify-between  tw-gap-2 tw-text-sm sm:tw-grow-0 '>
                        <ArrowDownCircle
                          className={twMerge(
                            'tw-h-5 tw-w-5 tw-text-nau-green-dark tw-transition-all',
                            open && 'tw-rotate-180',
                          )}
                        />
                      </div>
                    </div>
                  )}
                  wrapperClassName='tw-border tw-rounded-lg'>
                  <div className='   '>
                    <InstitutionAdminEmployeeProductSettlements
                      employeeId={employee.employeeId}
                      institutionId={props.institutionId}
                      settlementId={props.settlementId}
                    />
                  </div>
                </MyDisclosure>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
