import React from 'react';

interface IncrementBoxProps {
  description: string;
  infoDescription: string;
  value: number;
  onChange: (value: number) => void;
}

const IncrementBox = ({ description, infoDescription, value, onChange }: IncrementBoxProps) => {
  const increment = () => {
    onChange(value + 1);
  };

  const decrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <div className='tw-flex tw-items-center tw-justify-center tw-space-x-4 tw-border-b tw-border-gray-200 tw-py-2 last:tw-border-none'>
      <div className='tw-flex tw-items-center tw-space-x-2'>
        <span className='tw-w-[180px] tw-whitespace-nowrap tw-text-gray-700'>{description}</span>
      </div>
      <div className='tw-flex tw-items-center tw-rounded tw-border tw-border-gray-300'>
        <button
          className='tw-px-4 tw-py-2 tw-text-blue-500 hover:tw-bg-gray-100 disabled:tw-opacity-50'
          disabled={value === 0}
          onClick={decrement}
          type='button'>
          -
        </button>
        <div className=' tw-px-4 tw-py-2 tw-text-gray-700'>{value}</div>
        <button className='tw-px-4 tw-py-2 tw-text-blue-500 hover:tw-bg-gray-100' onClick={increment} type='button'>
          +
        </button>
      </div>
    </div>
  );
};

export default IncrementBox;
