import XIcon from '../../Icons/XIcon';
import React from 'react';
import agent from '../../../api/agent';
import { IFOrderStatus } from '../../../enums/institutionFund.enums';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap/cjs';

interface IRemoveIFOrderButtonProps {
  ifOrderId: number;
  institutionId: number;
  onSave?: () => void;
}

export function RemoveIFOrderButton(props: IRemoveIFOrderButtonProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const removeIFOrder = () => {
    setIsLoading(true);
    agent.InstitutionAdmin.changeInstitutionFundStatus({
      iFOrderId: props.ifOrderId,
      status: IFOrderStatus.Cancelled,
      institutionId: props.institutionId,
    })
      .then(() => {
        toast.success('Zapisano pomyślnie');
        props.onSave?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={' tw-cursor-pointer tw-transition-all hover:tw-scale-110'} onClick={() => removeIFOrder()}>
      {isLoading ? (
        <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='dark' />
      ) : (
        <XIcon className={'tw-h-5'} />
      )}
    </div>
  );
}
