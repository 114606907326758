import { twMerge } from 'tailwind-merge';
import { IIconComponentProps } from '../../types/icons';

export default function XIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} fill='none' stroke='currentColor' strokeWidth={1.5} viewBox='0 0 24 24'>
      <path d='M6 18 18 6M6 6l12 12' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
