import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import styles from './styles.module.scss';
import { notificationIcon } from '../../Header/styles';
import { toast } from 'react-toastify';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import CinemaVoucherOrderSummaryModal from '../CinemaVoucherOrderSummaryModal/CinemaVoucherOrderSummaryModal';

export default observer(() => {
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { cinemaVoucherStore, modalStore } = useStore();

  const sendOrder = () => {
    if (show) {
      setIsSubmitting(true);
      cinemaVoucherStore
        .sendOrder(true, cinemaVoucherStore.promoId)
        .then((orderId) => {
          modalStore.openModal(<CinemaVoucherOrderSummaryModal orderId={orderId} />, 'lg', null);
        })
        .catch(() => {
          toast.error('Brak kodów do zamówienia.');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (cinemaVoucherStore.getCinemaVoucherCartQuantity > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [cinemaVoucherStore.getCinemaVoucherCartQuantity]);

  return (
    <>
      {show && (
        <button onClick={() => sendOrder()} style={notificationIcon}>
          {isSubmitting ? (
            <LoadingComponentPartial />
          ) : (
            <>
              <FontAwesomeIcon color='#fff' icon={faCartShopping} size='lg' />
              {cinemaVoucherStore.cinemaVoucherCartQuantity > 0 && (
                <span className={styles.counter}>{cinemaVoucherStore.getCinemaVoucherCartQuantity}</span>
              )}
            </>
          )}
        </button>
      )}
    </>
  );
});
