import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useStore } from '../../store/store';
import Header from '../../components/Header/Header';
import InstitutionAdminMainSideBar from '../../components/InstitutionAdmin/InstitutionAdminMainMenu/InstitutionAdminMainSideBar';
import { Redirect } from 'react-router-dom';
import { PathRoute } from '../../constants/pathRoute/Route';
import ProfileMenuSideBar from '../../components/AdminProfileMenu/ProfileMenuSideBar';
import useHeaderHeight from '../../hooks/useHeaderHeight';

interface InstitutionAdminDashboardProps {
  children: ReactNode;
}
export default observer(({ children }: InstitutionAdminDashboardProps) => {
  const { userStore } = useStore();
  const headerHeight = useHeaderHeight();

  return (
    <>
      {!userStore.isLoggedInstAdmin ? (
        <Redirect to={PathRoute.PUSTY} />
      ) : (
        <div>
          <InstitutionAdminMainSideBar />
          <ProfileMenuSideBar />
          <Header />
          <div style={{ height: `${headerHeight}px` }}>{/* Empty div for header height */}</div>

          <main>{children}</main>
        </div>
      )}
    </>
  );
});
