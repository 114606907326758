import { create } from 'zustand/react';
import { KlOrderFormM, KlSubvariantType } from '../../types/kl';

export enum KlOrderStep {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
  Step4 = 4,
}

interface KlOrderState {
  order: KlOrderFormM;
  updateOrder: (updatedOrder: Partial<KlOrderFormM>) => void;
  resetOrder: () => void;
  step: KlOrderStep;
  setStep: (step: KlOrderStep) => void;
  cartOpened: boolean;
  setCartOpened: (cartOpened: boolean) => void;
}

const defaultKlOrder: KlOrderFormM = {
  customerId: undefined,
  policyPositionId: undefined,
  policyPersonUbrId: undefined,
  variantId: undefined, // Default to 0; replace with actual default if needed
  subvariantType: KlSubvariantType.BASIC,
  startDate: undefined,
  endDate: undefined,
  country: '', // Default to empty string
  insurerSportExtension: false,
  insurerDiseaseExtension: false,
  insurerDiseaseExtensionAvailable: false,
  insurerFirstName: '',
  insurerLastName: '',
  insurerPesel: '',
  insurerPhone: '',
  insurerEmail: '',
  insurerStreet: '',
  insurerHouseNumber: '',
  insurerFlatNumber: '',
  insurerPostCode: '',
  insurerCity: '',
  mainIsInsured: false,
  positions: [], // Default to an empty array
  availableVariant: undefined, // Optional field, left as undefined
  variantOptions: [], // Default to an empty array
  approvalBonVoyage1: false,
  approvalBonVoyage2: false,
  approvalBonVoyage3: false,
  approvalBonVoyage4: false,
  approvalInterrisk1: false,
  approvalInterrisk2: false,
  approvalInterrisk3: false,
  approvalInterrisk4: false,
  approvalInterrisk5: false,
  approvalMarketing1: false,
  approvalMarketing2: false,
  approvalMarketingEmail: false,
  approvalMarketingSms: false,
  approvalMarketingPhone: false,
  approvalMarketingIvr: false,
  numberOfAdults: 0,
  numberOfChildren: 0,
  numberOfSeniors: 0,
};
const defaultCartOpened = true;

export const useKlStore = create<KlOrderState>((set) => ({
  order: defaultKlOrder,
  updateOrder: (updatedOrder) =>
    set((state) => ({
      order: {
        ...state.order,
        ...updatedOrder,
      },
    })),
  resetOrder: () =>
    set(() => ({
      order: defaultKlOrder,
    })),
  step: KlOrderStep.Step1,
  setStep: (step: KlOrderStep) =>
    set(() => ({
      step: step,
    })),
  cartOpened: defaultCartOpened,
  setCartOpened: (cartOpened: boolean) =>
    set(() => ({
      cartOpened: cartOpened,
    })),
}));
