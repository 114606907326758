import { phoneNumber } from '../../../Contact/styles';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useStore } from '../../../../store/store';

export default function KlBrInsuranceNotFoundInfo() {
  const { userStore, modalStore } = useStore();

  return (
    <div className=' '>
      <h2 className='tw-text-center tw-text-xl tw-font-semibold tw-text-nau-kids-gray'>Nie znaleziono ubezpieczenia</h2>
      <p className='tw-mt-4 tw-text-center tw-text-nau-kids-gray'>
        Przykro nam, ale nie zweryfikowaliśmy Twojego ubezpieczenia.
      </p>
      <p className='tw-mt-2 tw-text-center tw-text-nau-kids-gray'>
        Jeżeli uważasz, ze przysługuje Ci darmowe ubezpieczenie to prosimy o kontakt:
      </p>
      <div className={'tw-mt-6 tw-text-center'}>
        <p className={'tw-text-nau-sea-green'}>Biuro Obsługi Klienta</p>
        <p>czynne w godz. 8:00-18:00 (pn-pt)</p>
        <p style={phoneNumber}>22 696 72 72</p>
      </div>
      <div className={'tw-flex tw-justify-end'}>
        <Button className={'tw-mt-4'} onClick={() => modalStore.closeModal()} variant='primary'>
          Powrót
        </Button>
      </div>
    </div>
  );
}
