import { KlOrderStep, useKlStore } from '../../../store/klStore/klZustandStore';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import React from 'react';
import { KlOrderFormM } from '../../../types/kl';
import { Squares2X2Icon } from '@heroicons/react/24/outline';
import { FormikScrollToFieldError } from '../../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import { useStore } from '../../../store/store';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import KlPreviousStepButton from './KlPreviousStepButton';

interface IKlSummaryFormProps {
  afterSave?: () => void;
}
export default function KlSummaryForm(props: IKlSummaryFormProps) {
  const { order, setStep } = useKlStore();
  const { klStore } = useStore();

  return (
    <div className=''>
      <Formik
        enableReinitialize={true}
        initialValues={order}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<KlOrderFormM>) => {
          return klStore
            .saveOrder(order)
            .then((response) => {
              if (response != null) {
                if (response.redirectLink != null) {
                  window.location.href = response.redirectLink;
                } else {
                  history.push(PathRoute.KL_THANK_YOU_PAGE);
                }
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        // validationSchema={validationSchema}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form className='tw-flex tw-w-full tw-flex-col tw-gap-6'>
            <KlPreviousStepButton onClick={() => setStep(KlOrderStep.Step3)} />
            <div className={'tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '}>
              <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <Squares2X2Icon className=' tw-mr-1 tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Podsumowanie
              </div>
              {/* <div className={'tw-mb-4 tw-flex tw-justify-end'}> */}
              {/*   <Button onClick={() => modalStore.openModal(<KlPromoModal />, 'lg', null)} variant={'primary'}> */}
              {/*     Sprawdź promocje */}
              {/*   </Button> */}
              {/* </div> */}
              <div className={'tw-flex tw-flex-col tw-divide-y tw-divide-gray-300'}>
                {values.positions.map((position, index) => (
                  <div
                    className='tw-flex tw-items-center tw-justify-between tw-rounded-lg tw-bg-white tw-p-4 tw-shadow-sm'
                    key={index}>
                    <div className='tw-flex tw-flex-col tw-space-y-1'>
                      <div className='tw-text-lg tw-font-bold'>
                        {position.firstName} {position.lastName}
                        {position.isMain && (
                          <span className='tw-ml-2 tw-text-sm tw-font-medium tw-text-green-500'>(Ubezpieczający)</span>
                        )}
                      </div>
                      <div className='tw-text-sm tw-text-gray-600'>Pesel: {position.pesel}</div>
                      <div className='tw-flex tw-space-x-2'>
                        {position.sportExtension && (
                          <span
                            className={`tw-rounded tw-px-2 tw-py-1 tw-text-xs ${
                              position.sportExtension
                                ? 'tw-bg-green-100 tw-text-green-700'
                                : 'tw-bg-gray-100 tw-text-gray-600'
                            }`}>
                            Rozszerzenie sportowe
                          </span>
                        )}

                        {position.diseaseExtension && (
                          <span
                            className={`tw-rounded tw-px-2 tw-py-1 tw-text-xs ${
                              position.diseaseExtension
                                ? 'tw-bg-green-100 tw-text-green-700'
                                : 'tw-bg-gray-100 tw-text-gray-600'
                            }`}>
                            Choroby przewlekłe
                          </span>
                        )}
                      </div>
                      {order.wasBrInsuranceVerified && !!position.info && (
                        <div className={'tw-mt-2 tw-text-sm'}>{position.info}</div>
                      )}
                    </div>
                    {/* <div> */}
                    {/*   <div className='tw-text-sm tw-text-gray-600'> */}
                    {/*     Cena: {getVariantPrice(position)} zł todo razy liczba dni */}
                    {/*   </div> */}
                    {/* </div> */}
                  </div>
                ))}
              </div>
              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'greenDark'}>
                  Zamów
                </MyButton>
              </div>
            </div>

            <FormikScrollToFieldError />
          </Form>
        )}
      </Formik>
    </div>
  );
}
