import SelectTripDestinationBox from './SelectTripDestinationBox';
import { GlobeAltIcon, GlobeEuropeAfricaIcon } from '@heroicons/react/24/outline';
import { KlVariantEnum } from '../../../enums/klVariants.enums';
import React from 'react';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { useKlStore } from '../../../store/klStore/klZustandStore';
import { toast } from 'react-toastify';

export default function TripDestinationBoxes() {
  const { order, updateOrder } = useKlStore();
  const { data: klVariants, isLoading: isLoadingKlVariants } = useSwrAgent().Kl.GetAllVariants();

  const updateOrderVariantId = (value: KlVariantEnum) => {
    const orderCopy = { ...order };
    if (orderCopy.wasBrInsuranceVerified && value === KlVariantEnum.World) {
      toast.error('Nie można wybrać wariantu Świat, jeśli zostało potwierdzone ubezpieczenie BR');
      updateOrder({ ...order, variantId: KlVariantEnum.EuropeNextTrip });
      return;
    }
    const variant = klVariants?.find((x) => x.id === value);
    orderCopy.positions = orderCopy.positions.map((pos) => {
      return { ...pos, variantId: value, availableVariant: variant };
    });
    orderCopy.variantId = value;
    orderCopy.variant = variant;
    updateOrder(orderCopy);
  };

  return (
    <>
      <SelectTripDestinationBox
        description={
          'Wszystkie kraje europejskie i wybrane kraje basenu Morza Śródziemnego (Turcja, Egipt, Tunezja, Maroko, Wyspy Kanaryjskie)'
        }
        icon={<GlobeEuropeAfricaIcon className=' tw-h-14 tw-w-14 tw-text-nau-sea-green' />}
        onChange={(value) => updateOrderVariantId(parseInt(value))}
        price={klVariants?.find((el) => el.id === KlVariantEnum.EuropeNextTrip)?.basePrice ?? 0}
        selectedValue={order.variantId?.toString()}
        title='Europa'
        value={KlVariantEnum.EuropeNextTrip.toString()}
      />
      {!order.wasBrInsuranceVerified && (
        <SelectTripDestinationBox
          description={'Cały świat'}
          icon={<GlobeAltIcon className=' tw-h-14 tw-w-14 tw-text-nau-sea-green' />}
          onChange={(value) => updateOrderVariantId(parseInt(value))}
          price={klVariants?.find((el) => el.id === KlVariantEnum.World)?.basePrice ?? 0}
          selectedValue={order.variantId?.toString()}
          title='Świat'
          value={KlVariantEnum.World.toString()}
        />
      )}
    </>
  );
}
