import React from 'react';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';

interface ISelectTripDestinationBoxProps {
  title: string;
  icon: JSX.Element;
  description: string;
  value: string;
  selectedValue?: string;
  price: number;
  onChange: (value: string) => void;
}

const SelectTripDestinationBox = ({
  title,
  icon,
  description,
  value,
  selectedValue,
  onChange,
  price,
}: ISelectTripDestinationBoxProps) => {
  const isSelected = value === selectedValue;

  const handleClick = () => {
    onChange(value);
  };

  return (
    <div
      className={`tw-flex tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-rounded-lg tw-border tw-p-4 tw-text-center ${
        isSelected ? 'tw-border-green-500 tw-bg-green-100' : 'tw-border-gray-300'
      }`}
      onClick={handleClick}>
      <div className='tw-mb-2 tw-flex tw-w-full tw-items-center tw-gap-2'>
        <div
          className={`tw-flex tw-h-5 tw-w-5  tw-items-center tw-justify-center tw-rounded-full tw-border ${
            isSelected ? 'tw-border-green-500 tw-bg-green-500' : 'tw-border-gray-300'
          }`}>
          {isSelected && <div className='tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-white'></div>}
        </div>
        <span className='tw-flex-1 tw-text-center tw-text-lg tw-font-bold'>
          {title}
          <Tooltip className={'tw-max-w-full'} id='tooltip-kl' place={'right'} />
          <InformationCircleIcon
            className='tw--mt-2 tw-ml-1 tw-inline-block tw-h-5 tw-w-5 tw-text-nau-sea-green'
            data-tooltip-content={description}
            data-tooltip-delay-show={200}
            data-tooltip-id='tooltip-kl'
            onClick={(e) => e.stopPropagation()}
          />
        </span>
      </div>
      <p className='tw-text-gray-600'>{icon}</p>
      <p className='tw-pb-2 tw-pt-3 tw-text-sm  tw-text-nau-kids-gray'>
        Cena za dzień już od <span className={'tw-text-base tw-font-semibold'}>{price} zł</span>
      </p>
      <MyButton
        className='tw-mt-4 tw-w-full tw-rounded tw-bg-green-500 tw-px-4 tw-py-2 tw-font-bold tw-text-white hover:tw-bg-green-600'
        onClick={() => {
          handleClick();
        }}
        type={'button'}
        variant={'primary'}>
        {isSelected ? 'WYBRANO' : 'WYBIERZ'}
      </MyButton>
    </div>
  );
};

export default SelectTripDestinationBox;
