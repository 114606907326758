import { IIconComponentProps } from '../../types/icons';
import { twMerge } from '../../index';

export default function EllipseIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      height='76'
      viewBox='0 0 76 76'
      width='76'
      xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <filter filterUnits='userSpaceOnUse' height='76' id='Ellipse_70' width='76' x='0' y='0'>
          <feOffset dy='3' />
          <feGaussianBlur result='blur' stdDeviation='5' />
          <feFlood floodOpacity='0.729' result='color' />
          <feComposite in='SourceGraphic' in2='blur' operator='out' />
          <feComposite in='color' operator='in' />
          <feComposite in2='SourceGraphic' operator='in' />
        </filter>
      </defs>
      <g data-type='innerShadowGroup'>
        <circle cx='38' cy='38' data-name='Ellipse 70' fill='currentColor' id='Ellipse_70-2' r='38' />
        <g filter='url(#Ellipse_70)' transform='matrix(1, 0, 0, 1, 0, 0)'>
          <circle cx='38' cy='38' data-name='Ellipse 70' fill='#fff' id='Ellipse_70-3' r='38' />
        </g>
      </g>
    </svg>
  );
}
