import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { Image, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { NavLink } from 'react-router-dom';
import { institutionAdminDashboardRoutes } from '../../../routes/routes';
import { Route } from '../../../types/route';
import {
  container,
  firstMenuItem,
  imageDiv,
  menuItem,
  mobileContainer,
  navLink,
  subMenuItem,
  upperMenu,
  webContainer,
} from './styles';
import _ from 'lodash';
import { User } from '../../../types/user';

export default observer(() => {
  const { menuStore, userStore } = useStore();
  const [, setUser] = useState<User | undefined>(undefined);
  const [mainRoutes, setMainRoutes] = useState<Route[]>([]);

  useEffect(() => {
    setUser(userStore.getUserData());
    setMainRoutes(institutionAdminDashboardRoutes);
  }, []);

  const handleClose = () => {
    menuStore.setClosedMainCustomer();
  };
  return (
    <Navbar className={'navbar-vertical'} expand={false} id='main-nav' variant='light'>
      <Offcanvas
        onHide={handleClose}
        show={menuStore.checkIsMainCustomerToggled}
        style={menuStore.isMobileView ? _.merge({}, container, mobileContainer) : _.merge({}, container, webContainer)}>
        <div className='d-flex justify-content-start'>
          <Image alt='logo' className='logo' src='/assets/logo.svg' style={imageDiv} />
        </div>
        <div className='navbar-vertical-content scrollbar' id='MainMenu'>
          <Nav as='ul' className='flex-column' style={upperMenu}>
            {mainRoutes.map((route, index) => (
              <Fragment key={route.name}>
                {(route.requiredInstitutionPermissions == undefined ||
                  route.requiredInstitutionPermissions.some((permission) =>
                    userStore.hasInstitutionPermission(permission),
                  )) && (
                  <Nav.Item
                    as='li'
                    key={route.name}
                    style={
                      index == 0
                        ? firstMenuItem
                        : route.subroutes && route.subroutes.length > 0
                          ? subMenuItem
                          : menuItem
                    }>
                    <NavLink onClick={handleClose} style={navLink} to={route.to}>
                      <div className='d-flex align-items-center'>
                        {route.icon && (
                          <span className='nav-link-icon'>
                            <FontAwesomeIcon color='#0bc268' icon={route.icon} />
                          </span>
                        )}
                        <span className='nav-link-text ps-1'>{route.name}</span>
                      </div>
                    </NavLink>
                  </Nav.Item>
                )}
              </Fragment>
            ))}
          </Nav>
        </div>
      </Offcanvas>
    </Navbar>
  );
});
