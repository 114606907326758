import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/store';
import { InstitutionRole } from '../../enums/roles.enums';
import React from 'react';
import MyButton from '../Buttons/MyButton/MyButton';
import SackDollarIcon from '../Icons/SackDollarIcon';
import NauPresentIcon from '../Icons/NauPresentIcon';
import { UserInstitution } from '../../types/user';
import EllipseIcon from '../Icons/EllipseIcon';

const ROLE_LABELS = {
  [InstitutionRole.SuperAdmin]: 'Administrator',
  [InstitutionRole.InstitutionAdmin]: 'Fundusz',
  [InstitutionRole.Customer]: 'Klub NAU',
};

const ROLE_SUBTITLES = {
  [InstitutionRole.SuperAdmin]: 'PEŁNA KONTROLA NAD PLACÓWKĄ',
  [InstitutionRole.InstitutionAdmin]: 'PEŁNA KONTROLA NAD TWOIM FUNDUSZEM',
  [InstitutionRole.Customer]: 'PROGRAM LOJALNOŚCIOWY',
};

function getRoleIcon(roleId: InstitutionRole) {
  switch (roleId) {
    case InstitutionRole.SuperAdmin:
      return (
        <svg className='tw-h-10 tw-text-stone-600' viewBox='0 0 640 512' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M335.5 4l288 160c15.4 8.6 21 28.1 12.4 43.5s-28.1 21-43.5 12.4L320 68.6 47.5 220c-15.4 8.6-34.9 3-43.5-12.4s-3-34.9 12.4-43.5L304.5 4c9.7-5.4 21.4-5.4 31.1 0zM320 160a40 40 0 1 1 0 80 40 40 0 1 1 0-80zM144 256a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm312 40a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM226.9 491.4L200 441.5l0 38.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-38.5L61.1 491.4c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l37.9-70.3c15.3-28.5 45.1-46.3 77.5-46.3l19.5 0c16.3 0 31.9 4.5 45.4 12.6l33.6-62.3c15.3-28.5 45.1-46.3 77.5-46.3l19.5 0c32.4 0 62.1 17.8 77.5 46.3l33.6 62.3c13.5-8.1 29.1-12.6 45.4-12.6l19.5 0c32.4 0 62.1 17.8 77.5 46.3l37.9 70.3c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8L552 441.5l0 38.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-38.5-26.9 49.9c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l36.3-67.5c-1.7-1.7-3.2-3.6-4.3-5.8L376 345.5l0 54.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-54.5-26.9 49.9c-1.2 2.2-2.6 4.1-4.3 5.8l36.3 67.5c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8z'
            fill='currentColor'
          />
        </svg>
      );
    case InstitutionRole.InstitutionAdmin:
      return <SackDollarIcon className='tw-h-10 tw-text-stone-600' />;
    case InstitutionRole.Customer:
      return <NauPresentIcon className='tw-h-10 tw-w-10 tw-text-stone-600' />;
    default:
      return null;
  }
}

export interface InstitutionSelectItemProps {
  item: UserInstitution;
  icon?: React.ReactNode;
  header?: string;
  headerDescription?: string;
  lowerDescription?: string;
}

const InstitutionSelectItem = observer((props: InstitutionSelectItemProps) => {
  const { userStore } = useStore();

  const handleClick = () => {
    userStore.setUserInstitution(props.item);
  };

  return (
    <div
      className='tw-flex tw-w-full tw-cursor-pointer tw-rounded-xl tw-border tw-border-gray-300 tw-p-6 tw-shadow tw-transition-all hover:tw-scale-105'
      onClick={handleClick}>
      <div className='tw-flex tw-w-full tw-flex-col tw-gap-2 tw-text-left'>
        <div className='tw-flex tw-text-2xl '>
          <div className={'tw-relative'}>
            <EllipseIcon className={' tw-h-[76px] tw-w-[76px] tw-text-nau-green-light-2 '} />
            {props.icon}
          </div>
          <div className={'tw-ml-6'}>
            <div className={'tw-text-3xl tw-font-bold tw-text-nau-green-dark'}>{props.header}</div>
            {props.headerDescription && (
              <div className='tw-text-base tw-font-normal tw-text-nau-green-dark'>{props.headerDescription}</div>
            )}
          </div>
        </div>

        <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-6'>
          {props.lowerDescription && (
            <div className={'tw-font-light tw-text-nau-green-dark'}>{props.lowerDescription}</div>
          )}
          <MyButton className='tw-w-full' variant='primary'>
            Sprawdź
          </MyButton>
        </div>
      </div>
    </div>
  );
});

export default InstitutionSelectItem;
