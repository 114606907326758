import React from 'react';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingContainer from '../../Loadings/LoadingContainer/LoadingContainer';

interface IInstitutionAdminEmployeeProductSettlementsProps {
  settlementId: number;
  institutionId: number;
  employeeId: number;
  onSave?: () => void;
}

export function InstitutionAdminEmployeeProductSettlements(props: IInstitutionAdminEmployeeProductSettlementsProps) {
  const {
    data: employeeProductSettlements,
    isLoading,
    mutate,
  } = useSwrAgent().InstitutionAdmin.GetEmployeeProductSettlements(
    props.settlementId,
    props.institutionId,
    props.employeeId,
  );

  return (
    <div className={'tw-relative tw-min-h-[40px]'}>
      {isLoading && <LoadingContainer />}
      {employeeProductSettlements?.map((settlement) => (
        <div
          className='tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 even:tw-bg-gray-100'
          key={settlement.id}>
          <div className='tw-font-semibold tw-text-gray-900'>{settlement.productName}</div>
          <div className={'tw-font-bold tw-text-nau-green-light'}>{settlement.amountFormatted}</div>
        </div>
      ))}
    </div>
  );
}
