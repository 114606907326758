import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { useStore } from '../../store/store';
import AccordionItem from 'react-bootstrap/AccordionItem';
import { Accordion, Image } from 'react-bootstrap';
import AccordionHeader from 'react-bootstrap/AccordionHeader';
import AccordionBody from 'react-bootstrap/AccordionBody';
import useSwrArticlesAxios from '../../api/useSwrArticlesAxios';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';

export default observer(() => {
  const { menuStore } = useStore();
  const { data: articles, isLoading } = useSwrArticlesAxios();

  return (
    <>
      {articles && (
        <>
          <LoadingComponent visible={isLoading} />
          <div className={'tw-rounded-lg tw-p-4'} id='News' style={styles.container as React.CSSProperties}>
            <div style={styles.containerNewsText as React.CSSProperties}>
              <p style={styles.textStyles as React.CSSProperties}>Aktualności</p>
            </div>
            {articles?.map((article) => (
              <div key={article.title}>
                <p style={styles.containerNewsTittle as React.CSSProperties}>{article.title}</p>
                <Accordion key={article.title} style={styles.marginStyles as React.CSSProperties}>
                  <div
                    style={
                      menuStore.isMobileView
                        ? ({
                            ...styles.restStylesMobileImageDiv,
                            width: '100%', // Set a fixed width
                            height: 'auto', // Maintain aspect ratio
                            minHeight: '200px', // Set a minimum height to avoid jump (adjust as needed)
                          } as React.CSSProperties)
                        : ({
                            ...styles.restStylesImageDiv,
                            width: '100%', // Set a fixed width
                            height: 'auto', // Maintain aspect ratio
                            minHeight: '300px', // Set a minimum height to avoid jump (adjust as needed)
                          } as React.CSSProperties)
                    }>
                    <Image
                      src={menuStore.isMobileView ? article.photo_thumb : article.photo}
                      style={menuStore.isMobileView ? styles.restStylesMobileImage : styles.restStylesImage}
                    />
                  </div>
                  <AccordionItem eventKey='1'>
                    <AccordionHeader color=''>
                      <div style={styles.checkStyles as React.CSSProperties}>sprawdź!</div>
                    </AccordionHeader>
                    <AccordionBody>
                      <div dangerouslySetInnerHTML={{ __html: article.long_content }} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
});
