import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import stylesScss from './styles.module.scss';
import { UserPayment } from '../../../types/payments';
import { Button, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { PaymentTabsE } from '../../../enums/paymentTabs.enums';
import { Benefit } from '../../../enums/benefits.enums';
import { useStore } from '../../../store/store';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-toastify';

interface ICreateAutopayPaymentFormProps {
  payments: UserPayment[];
}

export default observer((props: ICreateAutopayPaymentFormProps) => {
  const { paymentsStore, modalStore } = useStore();
  const [settlementIds, setSettlementIds] = useState<number[]>([]);
  const [productSettlementIds, setProductSettlementIds] = useState<number[]>([]);

  const createAutopayLink = () => {
    if (!settlementIds || !productSettlementIds || settlementIds.length === 0 || productSettlementIds.length === 0) {
      toast.error('Nie wybrano płatności');
      return;
    }
    paymentsStore.createAutopayLinkInSop(settlementIds, productSettlementIds).then((response) => {
      alert(response);
      if (response) {
        window.location.href = response;
      }
    });
  };
  const inputClassName = twMerge(
    'tw-h-4 tw-w-4 tw-cursor-pointer tw-rounded tw-ring-0 focus:tw-outline-0 focus:tw-ring-0 focus:tw-ring-transparent focus:tw-ring-offset-0',
    'tw-text-nau-gray tw-border-gray-300 tw-font-normal tw-mr-2',
  );

  return (
    <>
      {props.payments.map((payment) => (
        <div className={stylesScss.paymentItem} key={payment.id}>
          <div className={`tw-items-center'} tw-flex`}>
            <input
              checked={productSettlementIds.includes(payment.productSettlementId ?? 0)}
              className={inputClassName}
              onChange={(e) => {
                if (e.target.checked) {
                  if (payment.settlementId && payment.productSettlementId) {
                    setSettlementIds([...settlementIds, payment.settlementId]);
                    setProductSettlementIds([...productSettlementIds, payment.productSettlementId]);
                  }
                } else {
                  setSettlementIds(settlementIds.filter((id) => id !== payment.settlementId));
                  setProductSettlementIds(productSettlementIds.filter((id) => id !== payment.productSettlementId));
                }
              }}
              type='checkbox'
            />
          </div>
          <div className={stylesScss.dataContainer}>
            <div className={classNames(stylesScss.data, payment.late && stylesScss.expired)}>
              <div className={stylesScss.title}>{payment.benefit}</div>
              {/*<div className={stylesScss.description}> {payment.description}</div>*/}
              {payment.paymentDateFormatted && (
                <div className={stylesScss.paymentDate}>Termin zapłaty: {payment.paymentDateFormatted}</div>
              )}
            </div>
            <div className={stylesScss.amount}>{payment.amountToPayFormatted}</div>
          </div>
        </div>
      ))}
      <Button variant='primary' onClick={createAutopayLink} className='tw-mt-4'>
        Przejdź do płatności
      </Button>
    </>
  );
});
