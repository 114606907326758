import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import MyTextMaskedInput from '../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import { useStore } from '../../store/store';
import MyFooterContact from '../MyFooterContact/MyFooterContact';
import LogoHeader from '../LogoHeader/LogoHeader';
import MaskHelpers from '../../helpers/MaskHelpers';
import { PathRoute } from '../../constants/pathRoute/Route';
import { validationSchema } from '.';
import agent from '../../api/agent';
import SetNewPassword from '../SetNewPassword/SetNewPassword';
import MyButton from '../Buttons/MyButton/MyButton';
import { ACTIVATION_GUID } from '../../constants/sessionStorageKeys';
import { getPathOrRedirect } from '../../utils/linkUtils';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import { UserDataForFundAdminActivation } from '../../types/user';

interface IRememberPasswordProps {
  isUserActivation?: boolean;
  isUserActivationFund?: boolean;
}

export default observer((props: IRememberPasswordProps) => {
  const { userStore } = useStore();
  const [isNewPasswordView, setIsNewPasswordView] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [userDataForFundActivation, setUserDataForFundActivation] = useState<UserDataForFundAdminActivation>({
    phone: '',
    email: '',
    rks: undefined,
  } as UserDataForFundAdminActivation);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const guid = sessionStorage.getItem(ACTIVATION_GUID);
  if (userStore.isLoggedIn) {
    const redirectPath = getPathOrRedirect(PathRoute.PUSTY);
    return <Redirect to={redirectPath} />;
  }
  if (userStore.isLoggedIn) {
    const redirectPath = getPathOrRedirect(PathRoute.PUSTY);
    return <Redirect to={redirectPath} />;
  }

  if (props.isUserActivationFund) {
    if (guid) {
      userStore
        .getUserDataByInvitationGuid(guid)
        .then((data) => {
          setUserDataForFundActivation(data);
        })
        .finally(() => {
          if (isLoading) setIsLoading(false);
        });
    } else if (isLoading) {
      setIsLoading(false);
    }
  } else if (isLoading) {
    setIsLoading(false);
  }

  return isNewPasswordView ? (
    <SetNewPassword userId={userId} variant={props.isUserActivationFund ? 'registration' : 'forgotPassword'} />
  ) : (
    <div>
      {isLoading && <LoadingComponent />}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: userDataForFundActivation.email ?? '',
          phone: userDataForFundActivation.phone ?? '',
          guid: sessionStorage.getItem(ACTIVATION_GUID) ?? undefined,
          isFundActivation: props.isUserActivationFund ?? false,
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          agent.Account.forgotPassword(values)
            .then((userId) => {
              setUserId(userId.toString());
              setIsNewPasswordView(true);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}>
        {({ values, handleSubmit, isSubmitting, errors }) => (
          <Form
            autoComplete='off'
            className='tw-mx-auto tw-max-w-2xl tw-px-2'
            onSubmit={handleSubmit}
            style={{ paddingBottom: '55px', marginTop: '3vh' } as React.CSSProperties}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '8vh',
              }}>
              <LogoHeader />
            </div>
            <h2
              style={{
                color: 'teal',
                textAlign: 'center',
                marginBottom: '50px',
                marginTop: '4vh',
              }}>
              {props.isUserActivation && 'Aktywacja konta'}
              {props.isUserActivationFund && 'Pierwsze logowanie'}
              {!props.isUserActivation && !props.isUserActivationFund && 'Przypomnij hasło'}
            </h2>
            <label
              style={
                { fontSize: '12px', color: '#626d80', marginTop: '10px', marginLeft: '10px' } as React.CSSProperties
              }>
              Adres E-mail
            </label>
            <MyTextInput
              hiddenLabel={true}
              name='email'
              placeholder='Adres E-mail'
              disabled={props.isUserActivationFund}
            />
            <label
              style={
                { fontSize: '12px', color: '#626d80', marginTop: '10px', marginLeft: '10px' } as React.CSSProperties
              }>
              Telefon komórkowy
            </label>
            <MyTextMaskedInput
              hiddenLabel={true}
              mask={MaskHelpers.phone}
              name='phone'
              placeholder='Telefon komórkowy'
              disabled={props.isUserActivationFund}
            />
            {userDataForFundActivation.rks && (
              <p className={'tw-mt-4 tw-text-left tw-text-[12px] tw-text-nau-gray'}>
                W przypadku nieprawidłowego numeru telefonu, prosimy o kontakt z: <br />
                {userDataForFundActivation.rks}
              </p>
            )}
            <MyButton className={'tw-mt-8 tw-w-full'} isLoading={isSubmitting} type={'submit'} variant={'primary'}>
              Wyślij
            </MyButton>

            <div className='tw-flex tw-justify-end  tw-pt-6'>
              {!userStore.updateRequired && (
                <Link className={'tw-text-sm tw-text-nau-green-dark hover:tw-text-nau-green-dark'} to={PathRoute.LOGIN}>
                  Powrót do logowania
                </Link>
              )}
            </div>
            <MyFooterContact />
          </Form>
        )}
      </Formik>
    </div>
  );
});
