import React, { useState } from 'react';
import { twMerge } from '../../../index';
import EllipseIcon from '../../Icons/EllipseIcon';
import { RectangleGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { InstitutionAdminSettlementPerEmployeeDetails } from './InstitutionAdminSettlementPerEmployee';
import TabbedSelect from '../../Tabs/TabbedSelect';
import { InstitutionAdminSettlementPerProductDetails } from './InstitutionAdminSettlementPerProduct';

interface IInstitutionAdminSettlementsDetailsProps {
  settlementId: number;
  institutionId: number;
  onSave?: () => void;
}

enum SettlementTab {
  Products = 0,
  Workers = 1,
}

export function InstitutionAdminSettlementsDetails(props: IInstitutionAdminSettlementsDetailsProps) {
  const [activeTab, setActiveTab] = useState<SettlementTab | undefined>(undefined);

  return (
    <div>
      {activeTab === undefined && (
        <>
          <div className={'tw-flex tw-flex-col tw-gap-6 tw-px-2 tw-py-4'}>
            <div
              className={twMerge(
                'tw-flex tw-cursor-pointer tw-flex-col tw-gap-4 tw-rounded-xl',
                ' tw-border tw-border-gray-300 tw-p-4 tw-text-xl tw-shadow tw-transition-all hover:tw-scale-105',
              )}
              onClick={() => setActiveTab(SettlementTab.Products)}>
              <div className={'tw-flex tw-items-center tw-gap-4'}>
                <div className={'tw-relative'}>
                  <EllipseIcon className={' tw-h-[76px] tw-w-[76px] tw-text-[#0CB393] '} />
                  <RectangleGroupIcon
                    className={
                      'tw-absolute tw-left-1/2 tw-top-1/2  tw-z-50 tw-h-[58px] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-transform tw-text-white'
                    }
                  />
                </div>
                <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>Rozliczenia produktów</div>
              </div>
            </div>
            <div
              className={twMerge(
                'tw-flex tw-cursor-pointer tw-flex-col  tw-gap-4 tw-rounded-xl',
                ' tw-border tw-border-gray-300 tw-p-4 tw-text-xl tw-shadow tw-transition-all hover:tw-scale-105',
              )}
              onClick={() => setActiveTab(SettlementTab.Workers)}>
              <div className={'tw-flex tw-items-center tw-gap-4'}>
                <div className={'tw-relative'}>
                  <EllipseIcon className={' tw-h-[76px] tw-w-[76px] tw-text-[#0CB393] '} />
                  <UserIcon
                    className={
                      'tw-absolute tw-left-1/2 tw-top-1/2  tw-z-50 tw-h-[58px] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-transform tw-text-white'
                    }
                  />
                </div>
                <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>Rozliczenia pracowników</div>
              </div>
            </div>
          </div>
        </>
      )}

      {activeTab !== undefined && (
        <div className={'tw-mb-4 tw-flex tw-justify-center'}>
          <TabbedSelect
            onChange={(value) => setActiveTab(value)}
            tabs={[
              { name: 'Rozl. pracowników', value: SettlementTab.Workers },
              { name: 'Rozl. produktów', value: SettlementTab.Products },
            ]}
            value={activeTab}
          />
        </div>
      )}

      {activeTab === SettlementTab.Workers && (
        <>
          <InstitutionAdminSettlementPerEmployeeDetails
            institutionId={props.institutionId}
            settlementId={props.settlementId}
          />
        </>
      )}
      {activeTab === SettlementTab.Products && (
        <>
          <InstitutionAdminSettlementPerProductDetails
            institutionId={props.institutionId}
            settlementId={props.settlementId}
          />
        </>
      )}
    </div>
  );
}
