import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useStore } from '../../../../store/store';
import ConfettiExplosion from 'react-confetti-explosion';

export default function KlBrInsuranceVerifiedSuccessfullyInfo() {
  const { userStore, modalStore } = useStore();

  // State to control the second confetti explosion
  const [showSecondConfetti, setShowSecondConfetti] = useState(false);

  // Trigger the second confetti explosion after 1.5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSecondConfetti(true);
    }, 900);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div className='tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-green-light'>
        Weryfikacja przebieła pomyślnie
      </div>

      <div className='tw-my-8 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4'>
        <div className={'tw-text-2xl tw-font-bold tw-text-nau-gray lg:tw-text-4xl'}>Bezpieczna rodzina</div>
        <Image className={'tw-h-[180px]'} src={'/assets/kl/BR.png'} />
      </div>

      <p className='tw-text-center tw-text-lg tw-font-semibold tw-text-nau-kids-gray'>
        Weryfikacja głównego ubezpieczonego przebiegła pomyślnie. Jeśli będziesz ubezpieczać więcej osób, sprawdzimy ich
        zniżki w kolejnym kroku, podczas uzupełniania ich numerów PESEL.
      </p>

      <div className={'tw-flex tw-justify-between'}>
        <ConfettiExplosion duration={2000} zIndex={9999} />
        {showSecondConfetti && <ConfettiExplosion duration={2000} zIndex={9999} />}
      </div>

      <div className='tw-mt-8 tw-flex tw-justify-end'>
        <Button onClick={() => modalStore.closeModal()} variant='primary'>
          Kontynuuj
        </Button>
      </div>
    </div>
  );
}
