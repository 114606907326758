import { useKlStore } from '../../../store/klStore/klZustandStore';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import React from 'react';
import { KlOrderFormM, KlOrderPositionFormM, KlPersonType } from '../../../types/kl';
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  CubeTransparentIcon,
  GiftIcon,
  MagnifyingGlassCircleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import IncrementBox from '../../../components/IncrementBox';
import MyDateInput from '../../../components/FormControls/MyDateInput/MyDateInput';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FormikScrollToFieldError } from '../../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import { useStore } from '../../../store/store';
import KlPromotionVerificationForm from './KlPromotionVerification/KlPromotionVerificationForm';
import TripDestinationBoxes from './TripDestinationBoxes';
import KlSubvariants from './KlSubvariants';

export const defaultKlOrderPositionForm: KlOrderPositionFormM = {
  firstName: '',
  lastName: '',
  pesel: '',
  sportExtension: false,
  diseaseExtension: false,
  isMain: false,
  isVerified: false,
  diseaseExtensionAvailable: true,
  variantId: 1,
  availableVariant: undefined,
  variantOptions: [],
  personType: KlPersonType.ADULT,
};

interface IKlMainTripInfoFormProps {
  afterSave?: () => void;
}
export default function KlMainTripInfoForm(props: IKlMainTripInfoFormProps) {
  const { order, updateOrder } = useKlStore();
  const { userStore, modalStore } = useStore();

  const { data: klVariants, isLoading: isLoadingKlVariants } = useSwrAgent().Kl.GetAllVariants();

  const validationSchema = Yup.object().shape({
    // variantId: Yup.number().required('Pole jest wymagane'),
    startDate: Yup.string()
      .required('Pole jest wymagane')
      .test('is-today-or-future', 'Data rozpoczęcia musi być co najmniej dzisiejsza', (value) => {
        if (!value) return true; // Skip validation if the value is not set
        const today = new Date();
        const selectedDate = new Date(value);
        // Compare only the date portion (ignore time)
        return selectedDate >= new Date(today.setHours(0, 0, 0, 0));
      }),
    endDate: Yup.string()
      .required('Pole jest wymagane')
      .test('is-after-start-date', 'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia', function (value) {
        const { startDate } = this.parent;
        if (!startDate || !value) return true; // Skip validation if either date is missing
        return new Date(value) >= new Date(startDate);
      }),
  });

  const updateInsuredCount = (type: KlPersonType, count: number, values: KlOrderFormM) => {
    const valuesCopy = { ...values };

    const addPosition = (personType: KlPersonType) => {
      if (values.wasBrInsuranceVerified) {
        valuesCopy.positions.push({
          ...defaultKlOrderPositionForm,
          variantId: values.brVerificationVariant!.id,
          availableVariant: values.brVerificationVariant,
          personType,
          pesel: values.brVerificationPesel ?? '',
          isMain: true,
        });
      } else {
        valuesCopy.positions.push({
          ...defaultKlOrderPositionForm,
          variantId: values.variantId!,
          availableVariant: klVariants?.find((el) => el.id === values.variantId),
          personType,
        });
      }
    };

    const removePosition = (personType: KlPersonType) => {
      // if (valuesCopy.wasBrInsuranceVerified && personType === KlPersonType.ADULT && valuesCopy.numberOfAdults > 1) {
      //   const indexToRemove = valuesCopy.positions.findIndex(
      //     (position) => position.personType === personType && position.variantId !== values.brVerificationVariant?.id,
      //   );
      //   if (indexToRemove !== -1) {
      //     valuesCopy.positions.splice(indexToRemove, 1);
      //   }
      // } else {
      //   const indexToRemove = valuesCopy.positions.findIndex((position) => position.personType === personType);
      //   if (indexToRemove !== -1) {
      //     valuesCopy.positions.splice(indexToRemove, 1);
      //   }
      // }
      const indexToRemove = valuesCopy.positions.findIndex((position) => position.personType === personType);
      if (indexToRemove !== -1) {
        valuesCopy.positions.splice(indexToRemove, 1);
      }
    };

    const currentCount =
      type === KlPersonType.ADULT
        ? values.numberOfAdults
        : type === KlPersonType.CHILD
          ? values.numberOfChildren
          : values.numberOfSeniors;

    if (count > currentCount) {
      // Add one position
      addPosition(type);
    } else if (count < currentCount) {
      // Remove one position
      removePosition(type);
    }

    // Update the respective count
    if (type === KlPersonType.ADULT) {
      valuesCopy.numberOfAdults = count;
    } else if (type === KlPersonType.CHILD) {
      valuesCopy.numberOfChildren = count;
    } else if (type === KlPersonType.SENIOR) {
      valuesCopy.numberOfSeniors = count;
    }

    // Update the order with the modified values
    updateOrder(valuesCopy);
  };

  return (
    <div className=''>
      {isLoadingKlVariants && <LoadingComponent />}
      <Formik
        enableReinitialize={true}
        initialValues={order}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<KlOrderFormM>) => {
          if (values.numberOfAdults + values.numberOfChildren + values.numberOfSeniors === 0) {
            toast.error('Musisz dodać przynajmniej jedną osobę podróżującą');
            return;
          }
          if (!values.variantId) {
            toast.info('Prosimy wybrać cel podróży');
            return;
          }

          updateOrder({ ...values });
          props?.afterSave?.();
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form className='tw-flex tw-w-full tw-flex-col tw-gap-6'>
            <div className={'tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '}>
              <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <MagnifyingGlassCircleIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Wybierz
                cel swojej podróży
              </div>
              <div className={'tw-flex tw-gap-4'}>
                <TripDestinationBoxes />
              </div>
              {!order.wasBrInsuranceVerified && (
                <div className={'tw-mt-8'}>
                  <div className={' tw-border-t tw-border-gray-300  tw-text-nau-kids-gray'}>
                    <div className={'tw-mt-2 tw-cursor-pointer tw-px-2 tw-py-4 hover:tw-bg-gray-50'}>
                      <div
                        className={'tw-flex  tw-items-center tw-gap-4 tw-rounded-lg  '}
                        onClick={() => modalStore.openModal(<KlPromotionVerificationForm />, 'lg', null)}>
                        <div className={'tw-mb-2  tw-hidden tw-justify-center sm:tw-flex'}>
                          <GiftIcon className=' tw-h-14 tw-w-14 tw-text-nau-sea-green' />
                        </div>
                        <div>
                          Zweryfikuj swoje ubezpieczenie "Bezpieczna Rodzina" w NAU. Jeśli jesteś ubezpieczony,
                          otrzymasz <span className={'tw-font-bold'}>darmowe ubezpieczenie turystyczne</span>.
                        </div>
                        <div className={'tw-hidden sm:tw-block'}>
                          <ArrowRightIcon className=' tw-h-14 tw-w-14 tw-cursor-pointer tw-text-nau-sea-green' />
                        </div>
                      </div>

                      <MyButton
                        className='tw-mt-4 tw-w-full tw-rounded tw-bg-green-500 tw-px-4 tw-py-2 tw-font-bold tw-text-white hover:tw-bg-green-600 sm:tw-hidden'
                        onClick={() => modalStore.openModal(<KlPromotionVerificationForm />, 'lg', null)}
                        type={'button'}
                        variant={'primary'}>
                        Zweryfikuj
                      </MyButton>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <>
              <div className='tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-hidden sm:tw-px-6'>
                <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                  <CalendarDaysIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Termin podróży
                </div>

                <div className={'tw-flex tw-flex-col tw-gap-6'}>
                  <MyDateInput
                    label={'Data od'}
                    name='startDate'
                    onChange={(date) =>
                      updateOrder({
                        ...order,
                        startDate: date?.toDateString() ?? undefined,
                      })
                    }
                    value={order.startDate}
                  />
                  <MyDateInput
                    label={'Data do'}
                    name='endDate'
                    onChange={(date) =>
                      updateOrder({
                        ...order,
                        endDate: date?.toDateString() ?? undefined,
                      })
                    }
                    value={order.endDate}
                  />
                </div>
              </div>

              <div className='tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '>
                <div className={'tw-flex tw-gap-4'}>
                  <div className='tw-hidden tw-w-full tw-flex-1  tw-border-r tw-bg-white tw-px-2 sm:tw-block sm:tw-px-6'>
                    <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                      <CalendarDaysIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Termin
                      podróży
                    </div>

                    <div className={'tw-flex tw-flex-col tw-gap-6'}>
                      <MyDateInput
                        label={'Data od'}
                        name='startDate'
                        onChange={(date) =>
                          updateOrder({
                            ...order,
                            startDate: date?.toDateString() ?? undefined,
                          })
                        }
                        value={order.startDate}
                      />
                      <MyDateInput
                        label={'Data do'}
                        name='endDate'
                        onChange={(date) =>
                          updateOrder({
                            ...order,
                            endDate: date?.toDateString() ?? undefined,
                          })
                        }
                        value={order.endDate}
                      />
                    </div>
                  </div>
                  <div className={'tw-flex-1'}>
                    <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                      <UserCircleIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Liczba osób
                      podróżujących
                    </div>

                    <IncrementBox
                      description='Dzieci poniżej 30 lat'
                      infoDescription='Osoby młodsze niż 30 lat.'
                      onChange={(value) => updateInsuredCount(KlPersonType.CHILD, value, values)}
                      value={values.numberOfChildren}
                    />
                    <IncrementBox
                      description='Dorośli od 30 do 70 lat'
                      infoDescription='Osoby w wieku od 30 do 70 lat.'
                      onChange={(value) => updateInsuredCount(KlPersonType.ADULT, value, values)}
                      value={values.numberOfAdults}
                    />
                    <IncrementBox
                      description='Osoba powyżej 70 lat'
                      infoDescription='Osoby starsze niż 70 lat.'
                      onChange={(value) => updateInsuredCount(KlPersonType.SENIOR, value, values)}
                      value={values.numberOfSeniors}
                    />
                  </div>
                </div>
              </div>
            </>

            <div className='tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '>
              <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <CubeTransparentIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Wybierz pakiet
              </div>
              <KlSubvariants />
              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'greenDark'}>
                  Dalej
                </MyButton>
              </div>
            </div>

            <FormikScrollToFieldError />
          </Form>
        )}
      </Formik>
    </div>
  );
}
