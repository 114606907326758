import { IIconComponentProps } from '../../types/icons';
import { twMerge } from '../../index';

export default function BuildingSackIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      height='57.989'
      viewBox='0 0 43.269 57.989'
      width='43.269'
      xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='clip-path-xasdfdsfdssdf'>
          <path
            d='M26.543,38.194l-1.14-1.4L24.039,37.9l2.25,2.765,5.967-4.858-1.11-1.363ZM7.973,35.673l4.912.5.18-1.75-4.913-.5Zm.695-6.781,4.913.5.179-1.75-4.913-.5ZM21.5,36.143a21.736,21.736,0,0,1-.067-5.595,22.547,22.547,0,0,0,7.532-1.7A22.55,22.55,0,0,0,36,32.04c-.2,2.429-1.435,10.725-8.825,14.322A15.09,15.09,0,0,1,21.5,36.143m7.149-9.11c-3.03,1.659-7.95,1.792-8,1.793a.88.88,0,0,0-.843.708,22.241,22.241,0,0,0-.057,6.8,16.951,16.951,0,0,0,6.8,11.76.881.881,0,0,0,.871.089,16.945,16.945,0,0,0,9.045-10.136,22.243,22.243,0,0,0,1.323-6.67.881.881,0,0,0-.682-.865c-.048-.011-4.839-1.139-7.468-3.378a.88.88,0,0,0-.992-.1m-9.574-3.926,4.913.5.178-1.75-4.912-.5Zm-9.716-1,4.912.5.18-1.75-4.913-.5Zm10.411-5.786,4.913.5.179-1.75-4.913-.5Zm-9.716-1,4.913.5.179-1.749-4.912-.5Zm.268,35.591.7-6.859,6.859.7-.7,6.858ZM4.6,5.909,0,50.747a.879.879,0,0,0,.786.964l8.479.869,8.608.882,8.48.869a.88.88,0,0,0,.965-.786l.344-3.362-1.75-.178-.256,2.486L18.932,51.8l.792-7.734a.88.88,0,0,0-.785-.965l-8.609-.882a.88.88,0,0,0-.964.786l-.793,7.734-6.73-.689L6.258,6.963,30.076,9.4,28.423,25.543l1.75.179L31.916,8.707a.878.878,0,0,0-.786-.963L5.563,5.124a.786.786,0,0,0-.091,0,.878.878,0,0,0-.873.789M10.913,0a.88.88,0,0,0-.845.79L9.706,4.317l1.75.178.272-2.651L26.136,3.321l-.272,2.651,1.75.179.361-3.527a.88.88,0,0,0-.786-.965L11.032,0c-.02,0-.04,0-.061,0Z'
            data-name='Path 4801'
            fill='currentColor'
            id='Path_4801'
            transform='translate(0)'
          />
        </clipPath>
      </defs>
      <g data-name='Group 1539' id='Group_1539' transform='matrix(0.995, -0.105, 0.105, 0.995, 0, 3.951)'>
        <g clipPath='url(#clip-path-xasdfdsfdssdf)' data-name='Group 1538' id='Group_1538' transform='translate(0)'>
          <rect
            data-name='Rectangle 500'
            fill='currentColor'
            height='43.189'
            id='Rectangle_500'
            transform='translate(-5.558 53.815) rotate(-84.15)'
            width='57.954'
          />
        </g>
      </g>
    </svg>
  );
}
