import { IIconComponentProps } from '../../types/icons';
import { twMerge } from '../../index';

export default function PiggyBankIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      height='56.054'
      viewBox='0 0 74.99 56.054'
      width='74.99'
      xmlns='http://www.w3.org/2000/svg'>
      <g data-name='Group 1524' id='Group_1524' transform='translate(0 0)'>
        <g data-name='Group 1523' id='Group_1523' transform='translate(0 0)'>
          <path
            d='M37.7,30.731A3.951,3.951,0,0,1,34.91,34.5v2.3H32.384v-2.2A3.991,3.991,0,0,1,29.5,32.129l2.363-.9a1.448,1.448,0,0,0,1.343.931h.541a1.434,1.434,0,0,0,0-2.869H33.2v-.018a3.944,3.944,0,0,1-.819-7.763V19.274H34.91v2.238a3.983,3.983,0,0,1,2.711,2.425l-2.363.9a1.445,1.445,0,0,0-1.343-.933h-.539a1.434,1.434,0,1,0,0,2.869h.539v.018A3.955,3.955,0,0,1,37.7,30.731m37.05-11.268c-1.25,5.58-8.548,6.223-11.591,6.246.013.292.027.583.027.878,0,7.135-3.824,13.81-10.524,18.416l.478,9.726a1.266,1.266,0,0,1-1.262,1.326h-9.03a1.263,1.263,0,0,1-1.216-.921l-1.417-5.02a37.172,37.172,0,0,1-14.321-.06l-1.436,5.081a1.264,1.264,0,0,1-1.216.92H14.216a1.266,1.266,0,0,1-1.262-1.326l.488-9.951a25.6,25.6,0,0,1-7.462-8.064H1.263A1.263,1.263,0,0,1,0,35.45V22.828a1.263,1.263,0,0,1,1.263-1.263H3.892A22.4,22.4,0,0,1,9.56,11.72,72.289,72.289,0,0,0,6.888,2.676a1.262,1.262,0,0,1,.5-1.54C7.651.971,13.795-2.74,21.8,4.211A36.045,36.045,0,0,1,33.215,2.393c15.1,0,27.614,9.054,29.671,20.787,2.319.008,8.521-.345,9.4-4.272.3-1.356.359-3.125-1.108-3.858a2.409,2.409,0,0,0-2.5.132,1.473,1.473,0,0,0-.37,1.544c.145.22.664.074.819.024a1.263,1.263,0,1,1,.77,2.406,3.064,3.064,0,0,1-3.692-1.03,3.92,3.92,0,0,1,.552-4.584c1.163-1.363,3.65-1.7,5.548-.752,1.065.532,3.435,2.247,2.443,6.671M60.663,26.586C60.663,14.639,48.35,4.92,33.215,4.92A33.4,33.4,0,0,0,21.937,6.838a1.266,1.266,0,0,1-1.281-.261c-5.141-4.742-9.231-4.255-10.99-3.695a85.546,85.546,0,0,1,2.515,8.947,1.264,1.264,0,0,1-.346,1.188,19.791,19.791,0,0,0-5.7,10.069,1.263,1.263,0,0,1-1.238,1.007H2.527V34.186h4.2a1.265,1.265,0,0,1,1.118.675,22.992,22.992,0,0,0,7.6,8.227A1.264,1.264,0,0,1,16,44.2l-.457,9.328h6.746l1.5-5.309a1.26,1.26,0,0,1,1.508-.885,34.6,34.6,0,0,0,15.535.069,1.259,1.259,0,0,1,1.5.889l1.479,5.236h6.748l-.448-9.114a1.261,1.261,0,0,1,.571-1.12c6.346-4.141,9.986-10.229,9.986-16.707M28.951,10l.24,2.516c.141-.015,14.028-1.211,20.524,7.595l2.033-1.5C44.4,8.646,29.578,9.942,28.951,10m-14.8,8.984h-.008a1.267,1.267,0,1,0,.008,0'
            data-name='Path 4799'
            fill='currentColor'
            id='Path_4799'
            transform='translate(0 0)'
          />
        </g>
      </g>
    </svg>
  );
}
