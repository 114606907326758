import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { SportCardCompany } from '../../enums/sportCardCompany.enums';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import { SportCardPzuSubscriptionInfo } from './SubscriptionVariants/SportCardPzuSubscriptionInfo';
import { SportCardCompanies } from '../../constants/sportCard';
import { SportCardMultisportSubscriptionInfo } from './SubscriptionVariants/SportCardMultisportSubscriptionInfo';
import { SportCardMedicoverSubscriptionInfo } from './SubscriptionVariants/SportCardMedicoverSubscriptionInfo';
import { SportCardTabsE } from './SportCardTabs/SportCardTabs';

export default observer(() => {
  const [selectedCompany, setSelectedCompany] = useState<SportCardCompany | undefined>(undefined);

  const handleOrderClick = (company: SportCardCompany) => {
    history.push(PathRoute.SPORT_CARD + `?tab=${SportCardTabsE.OrderingView}&company=` + company);
  };

  return (
    <div>
      <div id={'sport-card-all-providers-offer-list-top'}></div>
      {!selectedCompany && (
        <div className={'tw-text-md tw-mt-4 tw-px-4  tw-pb-6 tw-font-bold tw-text-nau-green-dark'}>
          Wybierz kartę idealnie dopasowaną do Twoich potrzeb i ciesz się dostępem do najlepszych obiektów i usług
          sportowych.
        </div>
      )}

      {selectedCompany && (
        <MyButton
          className={'tw-w-fit'}
          onClick={() => {
            setSelectedCompany(undefined);
          }}
          variant={'primary'}>
          {'< '}Powrót
        </MyButton>
      )}

      <div className={'tw-flex tw-flex-col tw-items-center tw-gap-10 tw-pt-6'}>
        {SportCardCompanies.filter((company) => !selectedCompany || company.company === selectedCompany).map(
          ({ company, label, logoUrl }) => (
            <div
              className='tw-flex tw-w-full tw-max-w-[300px] tw-flex-col tw-items-center tw-overflow-hidden tw-rounded-xl tw-shadow-[0_3px_12px_rgba(0,0,0,0.25)]'
              key={company}>
              <img
                alt={label}
                className={
                  'tw-mb tw-w-full tw-bg-gradient-to-b tw-from-white tw-via-white tw-via-50% tw-to-[#bce4fc] tw-p-4' +
                  (company !== SportCardCompany.MultiSport ? '' : ' tw-opacity-50')
                }
                src={logoUrl}
              />
              <div className={'tw-w-full tw-px-6 tw-py-6'}>
                <MyButton
                  className={'tw-w-full' + (company !== SportCardCompany.MultiSport ? '' : ' tw-opacity-50')}
                  disabled={company === SportCardCompany.MultiSport}
                  onClick={() => {
                    if (selectedCompany) {
                      handleOrderClick(company);
                    } else {
                      const element = document.getElementById('sport-card-all-providers-offer-list-top');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                      }
                      setSelectedCompany(company);
                    }
                  }}
                  variant={'primary'}>
                  {selectedCompany ? 'Zamów' : 'Sprawdź'}
                </MyButton>
              </div>
            </div>
          ),
        )}
      </div>
      {selectedCompany && (
        <div className={'tw-mt-8 tw-flex tw-flex-col tw-gap-8 md:tw-p-6'}>
          {selectedCompany === SportCardCompany.PZU && <SportCardPzuSubscriptionInfo />}
          {selectedCompany === SportCardCompany.MultiSport && <SportCardMultisportSubscriptionInfo />}
          {selectedCompany === SportCardCompany.Medicover && <SportCardMedicoverSubscriptionInfo />}
        </div>
      )}
    </div>
  );
});
