import { useKlStore } from '../../../store/klStore/klZustandStore';

export default function useKlNumberOfDays() {
  const { order } = useKlStore();

  const getNumberOfDays = () => {
    return order.startDate && order.endDate
      ? Math.floor((new Date(order.endDate).getTime() - new Date(order.startDate).getTime()) / (1000 * 60 * 60 * 24)) +
          1
      : 0;
  };

  return getNumberOfDays();
}
