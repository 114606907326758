import { KlOrderStep, useKlStore } from '../../../store/klStore/klZustandStore';
import KlMainTripInfoForm from './KlMainTripInfoForm';
import KlInsuredDataForm from './KlInsuredDataForm';
import KlApprovalsForm from './KlApprovalsForm';
import KlSummaryForm from './KlSummaryForm';
import KlCart from './KlCart';
import TextStepIndicator from '../../../components/Steps/TextStepIndicator';
import MyCircleCheckIcon from '../../../components/Icons/MyCircleCheckIcon';
import React from 'react';
import { twMerge } from '../../../index';
import { Image } from 'react-bootstrap';

export default function KlOrderPublicForm() {
  const { step, setStep, order } = useKlStore();

  const scrollToTop = () => {
    const topElement = document.getElementById('kl-public-form-container');
    topElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div
      className={twMerge(
        'tw-flex tw-w-full tw-justify-center tw-gap-4 tw-px-2 tw-py-6 tw-pb-20',
        'lg:tw-px-6 lg:tw-pb-6',
      )}
      id={'kl-public-form-container'}>
      <div className={'tw-sticky tw-top-[100px] tw-hidden xl:tw-block'}>
        <div
          className={
            ' tw-top-[100px] tw-z-50 tw-hidden tw-flex-col tw-rounded-lg tw-border tw-bg-white tw-p-2 lg:tw-sticky xl:tw-flex'
          }>
          <div className={'tw-flex tw-flex-col tw-items-center tw-text-sm tw-text-nau-sea-green'}>
            <div className={' '}>Narciarstwo</div>
            <div className={' '}>Snowboarding</div>
            <div className={''}>Windsurfing</div>
            <div className={'tw-mb-2 '}>Narty wodne</div>
          </div>
          <div className={'tw-mb-2 tw-flex tw-w-[140px] tw-flex-col tw-items-center tw-gap-2'}>
            <div className={'tw-w-full tw-overflow-hidden tw-rounded-xl'}>
              <Image className={''} src={'/assets/kl/skier.jpg'} />
            </div>
          </div>

          <div className={'tw-flex tw-flex-col tw-items-center'}>
            <div className={' tw-text-sm  tw-font-semibold tw-text-nau-sea-green'}>U nas już od</div>
            <div className={'tw-mb-2 tw-text-sm  tw-font-semibold tw-text-nau-sea-green'}>2.5 zł za dzień</div>
          </div>
        </div>
      </div>
      <div className={'tw-min-h-screen tw-w-full tw-max-w-5xl'}>
        <div
          className={
            'tw-mb-6 tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-px-2 tw-py-2 sm:tw-px-6'
          }>
          <TextStepIndicator currentStep={step} steps={['Wybór oferty', 'Zgody', 'Dane osobowe', 'Podusmowanie']} />
          {order.wasBrInsuranceVerified && (
            <div
              className={
                'tw-mt-4 tw-flex tw-items-center tw-justify-center tw-gap-4 tw-font-semibold tw-text-nau-kids-gray'
              }>
              <MyCircleCheckIcon className={'tw-h-5 tw-w-5   tw-text-white'} />
              <div className={'tw-text-center'}>
                <div>Pozytywna weryfikacja ubezpieczenia</div>
                <div className={'tw-text-2xl tw-font-bold tw-text-nau-gray'}>Bezpieczna Rodzina</div>
              </div>

              <div>
                <Image className={'tw-h-[120px]'} src={'/assets/kl/BR.png'} />
              </div>
            </div>
          )}
        </div>

        {step === KlOrderStep.Step1 && (
          <KlMainTripInfoForm
            afterSave={() => {
              setStep(KlOrderStep.Step2);
              scrollToTop();
            }}
          />
        )}

        {step === KlOrderStep.Step2 && (
          <KlApprovalsForm
            afterSave={() => {
              setStep(KlOrderStep.Step3);
              scrollToTop();
            }}
          />
        )}

        {step === KlOrderStep.Step3 && (
          <KlInsuredDataForm
            afterSave={() => {
              setStep(KlOrderStep.Step4);
              scrollToTop();
            }}
          />
        )}
        {step === KlOrderStep.Step4 && (
          <KlSummaryForm
            afterSave={() => {
              setStep(KlOrderStep.Step4);
              scrollToTop();
            }}
          />
        )}
      </div>

      <div className={'tw-fixed tw-z-50 lg:tw-sticky lg:tw-top-[100px] lg:tw-block'}>
        <KlCart />
      </div>
    </div>
  );
}
