import { KlOrderStep, useKlStore } from '../../../store/klStore/klZustandStore';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import React from 'react';
import {
  GetInsuredPersonVariantsModel,
  KlOrderFormM,
  KlOrderPositionFormM,
  KlPersonType,
  KlSubvariantType,
} from '../../../types/kl';
import { IdentificationIcon, InformationCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { FormikScrollToFieldError } from '../../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import { calculateAgeFromPesel } from '../../../utils/peselUtils';
import { Tooltip } from 'react-tooltip';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { AddressPromptingForm } from '../../../components/Address/AddressPromptingForm';
import * as Yup from 'yup';
import _ from 'lodash';
import { KlVariantEnum } from '../../../enums/klVariants.enums';
import agent from '../../../api/agent';
import KlPreviousStepButton from './KlPreviousStepButton';

const defaultKlOrderPositionForm: KlOrderPositionFormM = {
  firstName: '',
  lastName: '',
  pesel: '',
  sportExtension: false,
  diseaseExtension: false,
  isMain: false,
  isVerified: false,
  diseaseExtensionAvailable: true,
  variantId: 1,
  availableVariant: undefined,
  variantOptions: [],
  personType: KlPersonType.ADULT,
};

interface IKlInsuredDataFormProps {
  afterSave?: () => void;
}
export default function KlInsuredDataForm(props: IKlInsuredDataFormProps) {
  const { order, updateOrder, setStep } = useKlStore();

  const { data: klVariants, isLoading: isLoadingKlVariants } = useSwrAgent().Kl.GetAllVariants();

  const validationSchema = Yup.object().shape({
    positions: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required('Pole wymagane'), // these constraints take precedence
        lastName: Yup.string().required('Pole wymagane'), // these constraints take precedence
        pesel: Yup.string().required('Pole wymagane'), // these constraints take precedence
      }),
    ),
    insurerFirstName: Yup.string().required('Pole wymagane'),
    insurerLastName: Yup.string().required('Pole wymagane'),
    insurerPesel: Yup.string().required('Pole wymagane'),
    insurerPhone: Yup.string().required('Pole wymagane'),
    insurerEmail: Yup.string().required('Pole wymagane'),
    insurerStreet: Yup.string().required('Pole wymagane'),
    insurerHouseNumber: Yup.string().required('Pole wymagane'),
    insurerPostCode: Yup.string().required('Pole wymagane'),
    insurerCity: Yup.string().required('Pole wymagane'),
  });

  return (
    <div className='tw-w-full'>
      {isLoadingKlVariants && <LoadingComponent />}
      <Formik
        enableReinitialize={false}
        initialValues={order}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<KlOrderFormM>) => {
          const valuesCopy = _.cloneDeep(values);

          const model: GetInsuredPersonVariantsModel = {
            insurerPesel: values.insurerPesel,
            startDate: values.startDate,
            endDate: values.endDate,
            pesels: values.positions.map((x) => x.pesel),
            country: values.variantId == KlVariantEnum.World ? 'World' : 'Europe',
          };
          return agent.Kl.getInsuredPersonVariants(model).then((response) => {
            if (order.wasBrInsuranceVerified && order.subvariantType === KlSubvariantType.BASIC) {
              response.forEach((el) => {
                const position = valuesCopy.positions.find((x) => x.pesel === el.pesel);
                if (position) {
                  position.variantId = el.variantId;
                  position.availableVariant = klVariants?.find((x) => x.id === el.variantId);
                  position.info = el.info;
                }
              });
            }

            updateOrder(valuesCopy);
            props?.afterSave?.();
          });
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form className='tw-flex tw-w-full tw-flex-col tw-gap-6'>
            <KlPreviousStepButton onClick={() => setStep(KlOrderStep.Step2)} />
            <div className={'tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '}>
              <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <IdentificationIcon className=' tw-mr-1 tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' />{' '}
                Uzupełnij dane podróżnych
              </div>
              <div className={'tw-flex tw-flex-col tw-divide-y tw-divide-gray-300'}>
                {values.positions.map((position, positionIdx) => (
                  <div className={'tw-py-6'} key={positionIdx}>
                    <div
                      className={
                        'tw-mb-4 tw-flex tw-justify-between tw-text-lg tw-font-semibold tw-text-nau-kids-gray'
                      }>
                      <div>
                        <UserIcon className=' tw-inline-block tw-h-5 tw-w-5 tw-text-nau-kids-gray' /> Podróżny{' '}
                        {positionIdx + 1}
                      </div>
                      <div>
                        <MyCheckbox
                          className={'tw-text-nau-green-light'}
                          label={`To ja składam zamówienie`}
                          name={'positions[' + positionIdx + '].isMain'}
                          onChange={(event) => {
                            const value = event.target.checked;
                            const positionsCopy = [...values.positions];
                            positionsCopy.forEach((x) => (x.isMain = false));
                            positionsCopy[positionIdx].isMain = value;
                            setFieldValue('positions', positionsCopy);
                            if (value) {
                              setFieldValue('insurerFirstName', position.firstName);
                              setFieldValue('insurerLastName', position.lastName);
                              setFieldValue('insurerPesel', position.pesel);
                            }
                          }}
                          variant={'big'}
                        />
                      </div>
                    </div>
                    <div className={'tw-flex tw-flex-col tw-gap-2'}>
                      <div className={'tw-flex tw-gap-4'}>
                        <MyTextInput
                          label={'Imię'}
                          name={'positions[' + positionIdx + '].firstName'}
                          wrapperClassName={'tw-flex-1'}
                        />
                        <MyTextInput
                          label={'Nazwisko'}
                          name={'positions[' + positionIdx + '].lastName'}
                          wrapperClassName={'tw-flex-1'}
                        />
                      </div>

                      <MyTextInput
                        label={'Pesel'}
                        mask={MaskHelpers.pesel}
                        name={'positions[' + positionIdx + '].pesel'}
                        onChange={(value) => {
                          const age = calculateAgeFromPesel(value.target.value);
                          if (age > 65) {
                            const valuesCopy = { ...values };
                            valuesCopy.positions[positionIdx].diseaseExtension = false;
                            setFieldValue('positions[' + positionIdx + '].diseaseExtension', false);
                            updateOrder(valuesCopy);
                          }
                        }}
                      />
                    </div>
                    <div className={'tw-mt-4 tw-flex tw-cursor-pointer tw-flex-col tw-gap-2'}>
                      <MyCheckbox
                        className={'tw-text-nau-green-light'}
                        label={`+ amatorskie narciarstwo, snowboarding, windsurfing, narty wodne (${klVariants?.find((x) => x.id === position.variantId)?.sportPrice} zł/dzień)`}
                        name={'positions[' + positionIdx + '].sportExtension'}
                        onChange={(event) => {
                          const value = event.target.checked;
                          const orderCopy = { ...order };
                          orderCopy.positions[positionIdx].sportExtension = value;
                          updateOrder(orderCopy);
                        }}
                        variant={'big'}
                      />

                      <div className={'tw-flex tw-gap-2'}>
                        <MyCheckbox
                          className={'tw-text-nau-green-light'}
                          disabled={calculateAgeFromPesel(position.pesel) > 65}
                          label={`+ choroby przewlekłe (${klVariants?.find((x) => x.id === position.variantId)?.diseasePrice} zł/dzień)`}
                          name={'positions[' + positionIdx + '].diseaseExtension'}
                          onChange={(event) => {
                            const value = event.target.checked;
                            const orderCopy = { ...order };
                            orderCopy.positions[positionIdx].diseaseExtension = value;
                            updateOrder(orderCopy);
                          }}
                          variant={'big'}
                        />
                        <Tooltip className={'tw-max-w-full'} id='tooltip-disease-info' place={'right'} />

                        {calculateAgeFromPesel(position.pesel) > 65 && (
                          <InformationCircleIcon
                            className={'tw-h-6 tw-w-6 tw-text-nau-kids-gray'}
                            data-tooltip-content={
                              'Osoby powyżej 65 roku życia nie mogą wykupić dodatkowego ubezpieczenia na choroby przewlekłe'
                            }
                            data-tooltip-delay-show={200}
                            data-tooltip-id='tooltip-disease-info'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '>
              <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <UserIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Dane ubezpieczającego
              </div>

              <div className={'tw-flex tw-flex-col tw-gap-2'}>
                <div className={'tw-flex tw-gap-4'}>
                  <MyTextInput label={'Imię'} name={'insurerFirstName'} wrapperClassName={'tw-flex-1'} />
                  <MyTextInput label={'Nazwisko'} name={'insurerLastName'} wrapperClassName={'tw-flex-1'} />
                </div>
                <MyTextInput
                  disabled={order.wasBrInsuranceVerified}
                  label={'Pesel'}
                  mask={MaskHelpers.pesel}
                  name={'insurerPesel'}
                />
                <MyTextInput label={'Numer telefonu'} mask={MaskHelpers.phone} name={'insurerPhone'} />
                <MyTextInput label={'E-mail'} name={'insurerEmail'} />
                <AddressPromptingForm
                  cityFormikName={'insurerCity'}
                  flatNoFormikName={'insurerFlatNumber'}
                  houseNoFormikName={'insurerHouseNumber'}
                  postCodeFormikName={'insurerPostCode'}
                  streetFormikName={'insurerStreet'}
                />
              </div>
              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'greenDark'}>
                  Dalej
                </MyButton>
              </div>
            </div>

            <FormikScrollToFieldError />
          </Form>
        )}
      </Formik>
    </div>
  );
}
