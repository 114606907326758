import { twMerge } from '../../index';

interface TabbedSelectTab {
  name: string;
  value: any;
}

interface TabbedSelectProps {
  tabs: TabbedSelectTab[];
  value: number;
  onChange: (value: number) => void;
}

export default function TabbedSelect({ tabs, value, onChange }: TabbedSelectProps) {
  return (
    <div className='tw-'>
      <div className='tw-'>
        <nav className='tw-isolate tw-flex tw-w-fit tw-divide-x tw-divide-gray-200 tw-rounded-lg tw-border tw-shadow-md'>
          {tabs.map((tab, tabIdx) => (
            <div
              className={twMerge(
                tab.value === value ? 'tw-text-nau-green-dark' : 'tw-hover:text-gray-700 tw-text-gray-500',
                tabIdx === 0 ? 'tw-rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'tw-rounded-r-lg' : '',
                'tw-hover:bg-gray-50 tw-focus:z-10 tw-group tw-relative tw-min-w-0 tw-cursor-pointer tw-overflow-hidden tw-whitespace-nowrap tw-bg-white tw-px-4 tw-py-2 tw-text-center tw-text-sm tw-font-medium',
              )}
              key={tab.name}
              onClick={() => onChange(tab.value)}>
              <span>{tab.name}</span>
              <span
                aria-hidden='true'
                className={twMerge(
                  tab.value === value ? 'tw-bg-nau-green-dark' : 'tw-bg-transparent',
                  'tw-absolute tw-inset-x-0 tw-bottom-0 tw-h-0.5',
                )}
              />
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}
