import React from 'react';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingContainer from '../../Loadings/LoadingContainer/LoadingContainer';
import { CubeIcon } from '@heroicons/react/24/outline';

interface IInstitutionAdminSettlementPerProductDetailsProps {
  settlementId: number;
  institutionId: number;
  onSave?: () => void;
}

export function InstitutionAdminSettlementPerProductDetails(props: IInstitutionAdminSettlementPerProductDetailsProps) {
  const {
    data: productSettlements,
    isLoading,
    mutate,
  } = useSwrAgent().InstitutionAdmin.GetProductSettlementsForSettlement(props.settlementId, props.institutionId);

  return (
    <div className={'tw-relative tw-py-4'}>
      {isLoading && <LoadingContainer />}
      <div className={'tw-mb-8 '}>
        <div className={''}>
          <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>
            Lista produktów wraz z ich rozliczeniami
          </div>
        </div>
      </div>
      <div className='tw-grid tw-grid-cols-1 tw-gap-6 '>
        {productSettlements?.map((productSettlement) => (
          <div
            className='tw-flex tw-flex-col tw-gap-2 tw-rounded-lg tw-border tw-bg-white tw-shadow'
            key={productSettlement.id}>
            {/* Employee name */}
            <div className='tw-text-nau-text-1 tw-w-full tw-rounded-t-lg tw-px-2 tw-py-2 tw-text-lg tw-font-semibold'>
              <CubeIcon className={'tw-mr-2 tw-inline-block tw-h-5 tw-w-5 tw-text-gray-500'} />
              {productSettlement.productName}
            </div>
            <div className={'tw-p-2'}>
              <div className={'tw-flex tw-grow tw-justify-between'}>
                <div className={'tw-text-nau-text-1 tw-text-base tw-font-semibold'}></div>
                <div>
                  <div className={'tw-text-sm tw-text-gray-500'}>Kwota rozliczenia</div>
                  <div className={'tw-text-xl tw-font-bold tw-text-nau-green-light'}>
                    {productSettlement.amountFormatted}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
