import React from 'react';
import { KlSubvariantType } from '../../../types/kl';
import { useKlStore } from '../../../store/klStore/klZustandStore';
import { useKlCartSummary } from './useKlCartSummary';
import { twMerge } from '../../../index';
import { useSwrAgent } from '../../../api/useSwrAgent';
import _ from 'lodash';

type InsurancePackage = {
  title: string;
  subvariant: KlSubvariantType;
  isRecommended?: boolean;
  details: string[];
  dayPrice: number;
  price: number;
  buttonLabel: string;
  isSelected: boolean;
};

const KlSubvariants = () => {
  const { order, updateOrder } = useKlStore();
  const { calculateCartSummary } = useKlCartSummary();
  const { data: klVariants, isLoading: isLoadingKlVariants } = useSwrAgent().Kl.GetAllVariants();

  const packages: InsurancePackage[] = [
    {
      subvariant: KlSubvariantType.BASIC,
      title: 'Interisk',
      isSelected: order.subvariantType === KlSubvariantType.BASIC,
      details: ['200 000 zł', 'bez limitu', '1 000 000 zł', '10 000 zł', '5 000 zł', '100 000 zł', '1 000 zł', '✔️'],
      dayPrice: order.variant?.basePrice ?? 0,
      price: 1060,
      buttonLabel: 'Wybrane',
      isRecommended: true,
    },
    {
      title: 'PZU',
      subvariant: KlSubvariantType.OPTIMAL,
      isSelected: order.subvariantType === KlSubvariantType.OPTIMAL,

      details: ['400 000 zł', 'bez limitu', '1 000 000 zł', '25 000 zł', '5 000 zł', '300 000 zł', '3 000 zł', '✔️'],
      dayPrice: (order.variant?.basePrice ?? 0) + 1,
      price: 1060,
      buttonLabel: 'Wybierz',
    },
    {
      title: 'Axa',
      subvariant: KlSubvariantType.ELASTIC,
      isSelected: order.subvariantType === KlSubvariantType.ELASTIC,
      details: ['600 000 zł', 'bez limitu', '1 000 000 zł', '50 000 zł', '5 000 zł', '500 000 zł', '5 000 zł', '✔️'],
      dayPrice: (order.variant?.basePrice ?? 0) + 2,
      price: 1060,
      buttonLabel: 'Wybierz',
    },
  ];

  const labels = [
    'Koszty leczenia',
    'Ratownictwo i transport medyczny',
    'Organizacja pomocy na miejscu (Assistance)',
    'NNW',
    'Kontynuacja leczenia w Polsce',
    'Odpowiedzialność cywilna (OC)',
    'Ochrona bagażu',
    'Sporty zimowe',
  ];

  const handleSelect = (subvariant: KlSubvariantType) => {
    if (
      subvariant === KlSubvariantType.ELASTIC ||
      subvariant === KlSubvariantType.OPTIMAL ||
      (subvariant === KlSubvariantType.BASIC && !order.wasBrInsuranceVerified)
    ) {
      const orderCopy = _.cloneDeep(order);
      orderCopy.positions.forEach((position) => {
        // if (position.personType === KlPersonType.ADULT) {
        position.variantId = orderCopy.variant?.id ?? 0;
        position.availableVariant = orderCopy.variant;
        // }
      });
      orderCopy.subvariantType = subvariant;
      updateOrder(orderCopy);
    } else {
      const orderCopy = _.cloneDeep(order);
      orderCopy.positions.forEach((position) => {
        // if (position.personType === KlPersonType.ADULT) {
        position.variantId = orderCopy.brVerificationVariant?.id ?? 0;
        position.availableVariant = orderCopy.brVerificationVariant;
        // }
      });
      orderCopy.subvariantType = subvariant;
      updateOrder(orderCopy);
    }
  };

  const getDayPrice = (actualPackage: InsurancePackage) => {
    if (order.wasBrInsuranceVerified && actualPackage.subvariant === KlSubvariantType.BASIC) {
      return 0;
    }
    return actualPackage.dayPrice;
  };

  const getPrice = (actualPackage: InsurancePackage) => {
    if (order.wasBrInsuranceVerified && actualPackage.subvariant === KlSubvariantType.BASIC) {
      return 0;
    }
    return calculateCartSummary(actualPackage.subvariant, order.positions).promoSum;
  };

  return (
    <div className='tw-flex tw-items-start tw-justify-center tw-py-8'>
      {/* Labels Column */}
      <div className='tw-flex tw-min-w-0 tw-shrink tw-flex-col tw-pt-[50px] sm:tw-pt-[61px]'>
        {labels.map((label, index) => (
          <div
            className='tw-flex tw-h-[50px] tw-min-w-0 tw-items-center tw-truncate tw-whitespace-pre tw-border-b tw-text-xs tw-font-medium tw-text-gray-700 sm:tw-text-sm '
            key={index}>
            <span className={'tw-truncate'}>{label}</span>
          </div>
        ))}
      </div>
      {/* Packages */}
      <div className='tw-ml-2 tw-flex tw-h-fit tw-flex-1 tw-gap-0.5 sm:tw-ml-4 sm:tw-gap-4'>
        {packages.map((pkg) => (
          <div
            className={`tw-relative tw-flex-1 tw-rounded-lg tw-border tw-bg-white tw-shadow-md ${
              pkg.isSelected ? 'nau-green-light' : 'tw-border-gray-300'
            }`}
            key={pkg.title}>
            {pkg.isRecommended && (
              <div className='tw-absolute tw-left-1/2  tw-top-[-35px] tw--translate-x-1/2 tw-rounded-lg tw-bg-nau-green-light tw-px-2 tw-py-0.5 tw-text-center tw-text-white'>
                Polecany
              </div>
            )}
            <div className='tw-relative tw-px-1 tw-py-4 tw-text-center tw-text-xs tw-font-bold sm:tw-text-lg'>
              {pkg.title}
              {order.wasBrInsuranceVerified && pkg.subvariant === KlSubvariantType.BASIC && (
                <div className={'tw-absolute tw-left-0 tw-right-0 tw-text-xs tw-text-nau-green-light'}>
                  Bezpieczna rodzina
                </div>
              )}
            </div>
            <div className='tw-px-4'>
              {pkg.details.map((detail, index) => (
                <div
                  className='tw-flex  tw-h-[50px] tw-items-center tw-justify-center tw-border-b tw-py-2 tw-text-xs sm:tw-text-sm '
                  key={index}>
                  <span>{detail}</span>
                </div>
              ))}
            </div>
            <div className='tw-pt-4 tw-text-center tw-text-xs  tw-text-gray-700  sm:tw-text-sm '>
              {getDayPrice(pkg)} zł/dzień
            </div>

            <div className='tw-py-4 tw-text-center  tw-text-xs tw-font-bold tw-text-gray-700 sm:tw-text-lg '>
              {getPrice(pkg)} zł
            </div>

            {pkg.subvariant === KlSubvariantType.BASIC && !order.wasBrInsuranceVerified && (
              <div className={twMerge('tw-pb-2 tw-text-center tw-text-xs  tw-text-nau-green-light ')}>
                <div>Możliwe</div>
                <div>0 zł z ubezpieczeniem</div>
                <div>Bezpieczna Rodzina</div>
              </div>
            )}

            <div className='tw-pb-4 tw-text-center'>
              <button
                className={`tw-rounded-lg tw-px-1 tw-py-2 tw-text-xs tw-font-medium sm:tw-px-4 sm:tw-text-lg ${
                  pkg.isSelected ? 'tw-bg-nau-green-light tw-text-white' : 'tw-bg-gray-200 tw-text-gray-700'
                }`}
                onClick={() => handleSelect(pkg.subvariant)}
                type={'button'}>
                {pkg.buttonLabel}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KlSubvariants;
