import { IIconComponentProps } from '../../types/icons';
import { twMerge } from '../../index';

export default function PresentIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      height='45.712'
      viewBox='0 0 40.168 45.712'
      width='40.168'
      xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 487' fill='none' height='45.712' id='Rectangle_487' width='40.168' />
        </clipPath>
      </defs>
      <g data-name='Group 1516' id='Group_1516' transform='translate(0 0)'>
        <g clipPath='url(#clip-path)' data-name='Group 1515' id='Group_1515' transform='translate(0 0)'>
          <path
            d='M38.933,10.929H31.492c3.395-1.475,5.986-3.467,6.353-5.574.242-1.4-.274-3.352-4.112-4.729A8.832,8.832,0,0,0,26.364,1c-3.137,1.651-5.141,5.03-6.279,7.585C18.947,6.025,16.943,2.646,13.806,1A8.832,8.832,0,0,0,6.438.626C2.6,2,2.083,3.958,2.326,5.355c.367,2.107,2.958,4.1,6.354,5.574H1.236A1.237,1.237,0,0,0,0,12.165V21.43a1.237,1.237,0,0,0,1.236,1.236H2.689V44.475a1.236,1.236,0,0,0,1.236,1.236H36.244a1.235,1.235,0,0,0,1.235-1.236V22.667h1.454a1.237,1.237,0,0,0,1.236-1.236V12.165a1.237,1.237,0,0,0-1.236-1.236M13.654,43.239H5.16V22.665h8.494Zm0-23.045H2.473V13.4H13.654ZM4.762,4.931c-.165-.94,1.88-1.752,2.512-1.978a7.554,7.554,0,0,1,2.556-.481,5.967,5.967,0,0,1,2.819.709c3.031,1.592,4.864,5.466,5.7,7.679-6.673-.526-13.234-3.9-13.587-5.929M24.05,21.43V43.239H16.127V13.4H24.05ZM21.822,10.86c.836-2.214,2.669-6.088,5.7-7.679a5.971,5.971,0,0,1,2.819-.709,7.56,7.56,0,0,1,2.557.481c.631.226,2.675,1.038,2.51,1.978-.352,2.028-6.913,5.4-13.586,5.929M35.007,43.239H26.523V22.667h8.485ZM37.7,20.194H26.523V13.4H37.7Z'
            data-name='Path 4797'
            fill='currentColor'
            id='Path_4797'
            transform='translate(0 0)'
          />
        </g>
      </g>
    </svg>
  );
}
