import { useCallback, useEffect, useState } from 'react';
import { useKlStore } from '../../../store/klStore/klZustandStore';
import { KlVariantEnum } from '../../../enums/klVariants.enums';
import { KlOrderPositionFormM, KlSubvariantType } from '../../../types/kl';
import useKlNumberOfDays from './useKlNumberOfDays';

interface KlCartPosition extends KlOrderPositionFormM {
  diseasePrice?: number;
  sportPrice?: number;
  basePrice: number;
  basePromoPrice: number;
}

export const useKlCartSummary = () => {
  const { order } = useKlStore();
  const [sum, setSum] = useState<number | undefined>(undefined);
  const [promoSum, setPromoSum] = useState<number>(0);
  const [positions, setPositions] = useState<KlCartPosition[]>([]);

  const numberOfDays = useKlNumberOfDays();
  const freeDays = 14;

  const calculateCartSummary = useCallback(
    (subvariantType: KlSubvariantType, positionsList: KlOrderPositionFormM[]) => {
      let totalSum = 0;
      let totalPromoSum = 0;

      const cartPositions = positionsList.map((position) => {
        const cartPosition: KlCartPosition = {
          ...position,
          basePrice: 0,
          basePromoPrice: 0,
        };

        const variantToUse =
          order.wasBrInsuranceVerified && subvariantType === KlSubvariantType.BASIC
            ? position.availableVariant
            : order.variant;

        let positionBasePrice = variantToUse?.basePrice ?? 0;

        // Adjust base price by subvariant type
        switch (subvariantType) {
          case KlSubvariantType.OPTIMAL:
            positionBasePrice += 1;
            break;
          case KlSubvariantType.ELASTIC:
            positionBasePrice += 2;
            break;
          default:
            break;
        }

        let positionSum = 0;

        if (position.availableVariant) {
          // Base and promo prices
          if (position.variantId === KlVariantEnum.EuropePartiallyFree) {
            cartPosition.basePrice = positionBasePrice * numberOfDays;
            cartPosition.basePromoPrice = positionBasePrice * Math.max(0, numberOfDays - freeDays);
          } else {
            cartPosition.basePrice = positionBasePrice * numberOfDays;
            cartPosition.basePromoPrice = positionBasePrice === 0 ? 0 : positionBasePrice * numberOfDays;
          }

          positionSum += cartPosition.basePrice;
          totalPromoSum += cartPosition.basePromoPrice;

          // Sport extension
          if (position.sportExtension) {
            const sportPrice = (position.availableVariant.sportPrice ?? 0) * numberOfDays;
            cartPosition.sportPrice = sportPrice;
            positionSum += sportPrice;
            totalPromoSum += sportPrice;
          }

          // Disease extension
          if (position.diseaseExtension) {
            const diseasePrice = (position.availableVariant.diseasePrice ?? 0) * numberOfDays;
            cartPosition.diseasePrice = diseasePrice;
            positionSum += diseasePrice;
            totalPromoSum += diseasePrice;
          }
        }

        totalSum += positionSum;
        return cartPosition;
      });

      return {
        sum: totalSum,
        promoSum: totalPromoSum,
        positions: cartPositions,
      };
    },
    [numberOfDays, freeDays, order],
  );

  useEffect(() => {
    if (order.positions) {
      const { sum: s, promoSum: p, positions: pos } = calculateCartSummary(order.subvariantType, order.positions);
      setSum(s);
      setPromoSum(p);
      setPositions(pos);
    }
  }, [order, calculateCartSummary]);

  return {
    sum,
    promoSum,
    positions,
    calculateCartSummary,
  };
};
