import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { CinemaVoucherOrderDataM } from '../../../types/cinemaVoucher';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { CinemaVoucherOrderPromo } from '../../../enums/cinemaVoucherOrderPromo.enum';
import agent from '../../../api/agent';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { UserPromotionE } from '../../../enums/userPromotion.enums';

export default observer((props: { orderId: number }) => {
  const { cinemaVoucherStore, modalStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [order, setOrder] = useState<CinemaVoucherOrderDataM>({} as CinemaVoucherOrderDataM);

  const { data: userMarketingData, isLoading: areMarketingDataLoading } = useSwrAgent().Users.GetUserMarketingData();
  const isCinemaDirectorPromo =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectors);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    cinemaVoucherStore
      .checkUserHasCinemaVoucherPersonalData()
      .then((resp) => {
        if (resp) {
          sendOrder();
        } else {
          history.push(PathRoute.CINEMA_VOUCHER_ORDER_CLIENT_DATA + '/' + props.orderId);
        }
      })
      .finally(() => {
        modalStore.closeModal();
        setIsSubmitting(false);
      });
  };

  const sendOrder = () => {
    if (order.orderPromoId === CinemaVoucherOrderPromo.InstitutionDirectorPromo) {
      agent.CinemaVouchers.orderDirectorPromoCinemaVouchers(props.orderId)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else if (order.orderPromoId === CinemaVoucherOrderPromo.NauEmployeePromo) {
      agent.CinemaVouchers.orderNauEmployeePromoCinemaVouchers(props.orderId)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_NAU_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else if (order.orderPromoId === CinemaVoucherOrderPromo.PersonalPromotion) {
      agent.CinemaVouchers.orderPersonalPromoCinemaVouchers(props.orderId)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_NAU_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else if (order.orderPromoId === CinemaVoucherOrderPromo.GrandparentsDay) {
      agent.CinemaVouchers.orderGrandparentsPromoCinemaVouchers(props.orderId)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_NAU_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else {
      cinemaVoucherStore
        .orderCinemaVouchers(props.orderId)
        .then((resp) => {
          window.open(resp, '_self', 'location=no');
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    }
  };
  useEffect(() => {
    cinemaVoucherStore
      .getCinemaVoucherOrderData(props.orderId.toString())
      .then((resp) => {
        setOrder(resp);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  }, []);
  return (
    <div className={'tw-flex tw-justify-center tw-bg-white tw-leading-normal'}>
      {loading ? (
        <LoadingComponentPartial />
      ) : (
        <div className={'tw-max-w-lg tw-flex-1'}>
          <p className='tw-mb-8 tw-text-center tw-text-2xl'>Podsumowanie</p>
          <div className={'tw-flex tw-flex-col tw-gap-4'}>
            {order.vouchers.map((x, idx) => (
              <div className='tw-flex tw-w-full tw-justify-between tw-gap-4' key={idx}>
                <div>{x.companyName}</div>
                <div className={'tw-whitespace-nowrap'}>{x.details}</div>
              </div>
            ))}
            <div className='tw-flex tw-w-full tw-justify-between tw-gap-4'>
              <div>
                {order.orderPromoId != CinemaVoucherOrderPromo.InstitutionDirectorPromo &&
                  order.orderPromoId != CinemaVoucherOrderPromo.NauEmployeePromo &&
                  order.orderPromoId != CinemaVoucherOrderPromo.PersonalPromotion && (
                    <p className='tw-pb-2 tw-text-left'>+ Opłata serwisowa 1 zł</p>
                  )}
              </div>
            </div>
          </div>
          <div className={'tw-my-6 tw-h-[1px] tw-w-full tw-border-t tw-border-black'}></div>
          <div className='tw-flex tw-justify-between tw-pb-2'>
            <div>Suma</div>
            <div>{order.sum}</div>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-2 tw-p-4'>
            {isSubmitting ? (
              <Button className='tw-w-full' disabled={true}>
                <span className='tw-m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Ładowanie…
              </Button>
            ) : (
              <Button className='tw-w-full' onClick={handleConfirm}>
                Zamów
              </Button>
            )}
            <Button
              onClick={() => {
                history.push(
                  `${isCinemaDirectorPromo ? PathRoute.CINEMA_VOUCHER_DIRECTOR_PROMO : PathRoute.CINEMA_VOUCHER}?orderView=true`,
                );
                modalStore.closeModal();
              }}
              variant='secondary'>
              Wróć
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
