import React, { useState } from 'react';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useKlStore } from '../../../store/klStore/klZustandStore';
import { KlOrderPositionFormM, KlPersonType } from '../../../types/kl';
import { KlVariantEnum } from '../../../enums/klVariants.enums';
import { twMerge } from '../../..';
import { useKlCartSummary } from './useKlCartSummary';
import useKlNumberOfDays from './useKlNumberOfDays';

interface KlCartPosition extends KlOrderPositionFormM {
  diseasePrice?: number;
  sportPrice?: number;
  basePrice: number;
  basePromoPrice: number;
}
export default function KlCart() {
  const { order, cartOpened } = useKlStore();
  const [expanded, setExpanded] = useState(false);
  const numberOfDays = useKlNumberOfDays();
  const klCartSummary = useKlCartSummary();

  const sum = klCartSummary.sum ?? 0;

  const getPersonType = (personType?: number) => {
    if (personType === KlPersonType.ADULT) {
      return 'Dorosły';
    } else if (personType === KlPersonType.CHILD) {
      return 'Dziecko';
    } else if (personType === KlPersonType.SENIOR) {
      return 'Senior';
    }
    return 'Osoba';
  };

  const priceFormatted = (price: number) => {
    return (
      <div className={'tw-whitespace-nowrap'}>
        <span id='cart-price'>{Math.floor(price)}</span>
        <sup id='cart-price-decimal'>{(price % 1).toFixed(2).substring(2)}</sup>
        <span> zł</span>
      </div>
    );
  };

  const toggleCartVisibility = () => {
    //useKlStore.setState({ cartOpened: !cartOpened });
  };

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const renderPositions = (positions: KlCartPosition[]) => {
    return positions.map((position, idx) => (
      <div className='tw-border-t tw-border-gray-300 tw-py-2' key={`position${idx}`}>
        <div className='tw-mb-2 tw-text-sm tw-font-semibold'>
          {position.firstName && position.firstName !== ''
            ? position.firstName
            : `${getPersonType(position.personType)} ${idx + 1}`}
        </div>
        <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
          <div className={'tw-text-sm'}>Ubezpieczenie podstawowe</div>
          <div className='tw-flex tw-flex-col tw-items-center tw-gap-2 tw-text-sm'>
            {position.basePrice !== position.basePromoPrice && (
              <div className='tw-relative tw-w-[60px] tw-text-center tw-text-gray-500 tw-line-through'>
                {priceFormatted(position.basePrice)}
              </div>
            )}
            <div
              className={twMerge(
                'tw-text-right',
                position.basePromoPrice < position.basePrice && 'tw-font-semibold tw-text-green-600',
              )}>
              {priceFormatted(position.basePromoPrice)}
            </div>
          </div>
        </div>
        {position.sportPrice !== undefined && (
          <div className='tw-flex tw-justify-between tw-gap-2 tw-text-sm'>
            <p>Rozszerzenie o sporty</p>
            <div>{priceFormatted(position.sportPrice)}</div>
          </div>
        )}
        {position.diseasePrice !== undefined && (
          <div className='tw-flex tw-justify-between tw-gap-2 tw-text-sm'>
            <p>Rozszerzenie o choroby przewlekłe</p>
            <div>{priceFormatted(position.diseasePrice)}</div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div
      className={twMerge(
        ' tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-min-w-[250px] lg:tw-w-[250px]',
        'tw-z-50 tw-max-h-[85vh] tw-overflow-y-auto lg:tw-sticky lg:tw-top-[100px]',
      )}>
      <div
        className={twMerge(
          'tw-bg-white tw-shadow-md',
          'tw-p-4 ',
          'tw-transition-all tw-duration-300',
          'tw-rounded-lg',
          'tw-hidden lg:tw-block',
        )}>
        <div className='tw-flex tw-items-center tw-justify-between'>
          <strong className='tw-text-base md:tw-text-lg'>Twój koszyk</strong>
          <button
            className={twMerge('tw-ml-2 tw-inline-flex tw-items-center tw-text-sm md:tw-hidden')}
            onClick={toggleCartVisibility}
            type='button'>
            {cartOpened ? (
              <ChevronRightIcon className='tw-h-5 tw-w-5' />
            ) : (
              <ChevronLeftIcon className='tw-h-5 tw-w-5' />
            )}
          </button>
        </div>
        <div className={twMerge('tw-mt-4 tw-text-nau-kids-gray', cartOpened ? 'tw-block' : 'tw-hidden', 'md:tw-block')}>
          <div className='tw-space-y-1 tw-text-sm'>
            <div className='tw-flex tw-w-full tw-justify-between'>
              <span className={'tw-font-light '}>Liczba dni:</span>
              <span>{numberOfDays}</span>
            </div>
            <div className='tw-flex tw-w-full tw-justify-between'>
              <span className={'tw-font-light'}>Liczba osób:</span>
              <span>{klCartSummary.positions.length}</span>
            </div>
            <div className='tw-flex tw-w-full tw-justify-between tw-gap-2'>
              <span className={'tw-font-light'}>Cel podróży:</span>
              <span>{order.variantId ? (order.variantId === KlVariantEnum.World ? 'Świat' : 'Europa') : '-'}</span>
            </div>
          </div>
          <div className='tw-mt-4 '>
            {klCartSummary.positions.length > 0 ? (
              <>
                {renderPositions(klCartSummary.positions)}
                <div className='tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-300 tw-pt-4'>
                  <strong>Twoja cena</strong>
                  <div className='tw-flex tw-flex-col tw-items-center tw-gap-2'>
                    {klCartSummary.promoSum < (sum ?? 0) && (
                      <div className='tw-relative tw-w-[60px] tw-text-center tw-text-gray-500 tw-line-through'>
                        {priceFormatted(sum ?? 0)}
                      </div>
                    )}
                    <span className='tw-text-lg tw-font-semibold tw-text-green-600'>
                      {priceFormatted(klCartSummary.promoSum ?? 0)}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div className='tw-mt-4'>Koszyk jest pusty</div>
            )}
          </div>
        </div>

        {!cartOpened && (
          <div className='tw-mt-2 tw-flex tw-justify-end md:tw-hidden'>
            <span className='tw-text-base tw-font-semibold tw-text-green-700'>{priceFormatted(sum ?? 0)}</span>
          </div>
        )}
      </div>
      <div className={'lg:tw-hidden'}>
        {!expanded && (
          <div
            className={twMerge(
              'tw-flex tw-h-[50px] tw-items-center tw-justify-between tw-bg-white tw-px-4 tw-shadow-md',
            )}>
            <strong className='tw-text-lg'>Twoja cena:</strong>
            <div className='tw-flex tw-items-center'>
              <span className='tw-text-lg tw-text-green-600'>{sum?.toFixed(2)} zł</span>
              <button className='tw-ml-4' onClick={toggleExpanded}>
                <ChevronUpIcon className='tw-h-6 tw-w-6' />
              </button>
            </div>
          </div>
        )}
        {expanded && (
          <div className={twMerge('tw-border-t  tw-bg-white tw-px-4 tw-pt-4 tw-shadow-md tw-transition-all')}>
            <div className='tw-flex tw-items-center tw-justify-between'>
              <strong className='tw-text-lg'>Twój koszyk:</strong>
              <button className='tw-ml-4' onClick={toggleExpanded}>
                <ChevronDownIcon className='tw-h-6 tw-w-6' />
              </button>
            </div>

            <div className='tw-mt-4 tw-space-y-2 tw-text-sm'>
              <div className='tw-flex tw-justify-between'>
                <span>Liczba dni:</span>
                <span>{numberOfDays ?? 0}</span>
              </div>

              <div className='tw-flex tw-justify-between'>
                <span>Liczba osób:</span>
                <span>{order.positions?.length ?? 0}</span>
              </div>

              <div className='tw-flex tw-justify-between'>
                <span>Cel podróży:</span>
                <span>{order.variantId ? (order.variantId === 4 ? 'Świat' : 'Europa') : '-'}</span>
              </div>
            </div>

            <div className='tw-mt-4 '>
              {klCartSummary.positions.length > 0 ? (
                <>{renderPositions(klCartSummary.positions)}</>
              ) : (
                <div className='tw-mt-4'>Koszyk jest pusty</div>
              )}
            </div>

            <div className=' tw-flex tw-justify-between tw-border-t tw-py-4'>
              <strong>Twoja cena</strong>
              <div className='tw-flex  tw-items-center tw-gap-2'>
                {klCartSummary.promoSum < (sum ?? 0) && (
                  <div className='tw-relative tw-w-[60px] tw-text-center tw-text-gray-500 tw-line-through'>
                    {priceFormatted(sum ?? 0)}
                  </div>
                )}
                <span className='tw-text-lg tw-font-semibold tw-text-green-600'>
                  {priceFormatted(klCartSummary.promoSum ?? 0)}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
