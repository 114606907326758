import { useKlStore } from '../../../../store/klStore/klZustandStore';
import { useSwrAgent } from '../../../../api/useSwrAgent';
import LoadingComponent from '../../../../components/Loadings/LoadingComponent/LoadingComponent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../../components/Buttons/MyButton/MyButton';
import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import { FormikScrollToFieldError } from '../../../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import MyTextInput from '../../../../components/FormControls/MyTextInput/MyTextInput';
import * as Yup from 'yup';
import TripDestinationBoxes from '../TripDestinationBoxes';
import MyDateInput from '../../../../components/FormControls/MyDateInput/MyDateInput';
import { GetInsuredPersonVariantsModel, KlOrderFormM, KlSubvariantType } from '../../../../types/kl';
import agent from '../../../../api/agent';
import { KlVariantEnum } from '../../../../enums/klVariants.enums';
import MaskHelpers from '../../../../helpers/MaskHelpers';
import { toast } from 'react-toastify';
import { useStore } from '../../../../store/store';
import KlBrInsuranceNotFoundInfo from './KlBrInsuranceNotFoundInfo';
import KlBrInsuranceVerifiedSuccessfullyInfo from './KlBrInsuranceVerifiedSuccessfullyInfo';

interface IKlPromotionVerificationFormProps {
  afterSave?: () => void;
}

interface IKlPromotionVerificationFormValues {
  pesel: string;
}
export default function KlPromotionVerificationForm(props: IKlPromotionVerificationFormProps) {
  const { order, updateOrder, setStep } = useKlStore();
  const { userStore, modalStore } = useStore();

  const { data: klVariants, isLoading: isLoadingKlVariants } = useSwrAgent().Kl.GetAllVariants();

  const validationSchema = Yup.object().shape({
    insurerPesel: Yup.string().required('Pole wymagane'),
    startDate: Yup.string()
      .required('Pole jest wymagane')
      .test('is-today-or-future', 'Data rozpoczęcia musi być co najmniej dzisiejsza', (value) => {
        if (!value) return true; // Skip validation if the value is not set
        const today = new Date();
        const selectedDate = new Date(value);
        // Compare only the date portion (ignore time)
        return selectedDate >= new Date(today.setHours(0, 0, 0, 0));
      }),
    endDate: Yup.string()
      .required('Pole jest wymagane')
      .test('is-after-start-date', 'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia', function (value) {
        const { startDate } = this.parent;
        if (!startDate || !value) return true; // Skip validation if either date is missing
        return new Date(value) >= new Date(startDate);
      }),
  });

  return (
    <div className=''>
      {isLoadingKlVariants && <LoadingComponent />}
      <Formik
        enableReinitialize={true}
        initialValues={order}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<KlOrderFormM>) => {
          if (!values.variantId) {
            toast.info('Wybierz wariant');
            return;
          }
          if (values.variantId === KlVariantEnum.World) {
            toast.info('Promocja dostępna tylko dla Europy');
            return;
          }
          const model: GetInsuredPersonVariantsModel = {
            insurerPesel: values.insurerPesel,
            startDate: values.startDate,
            endDate: values.endDate,
            pesels: [values.insurerPesel],
            country: values.variantId == KlVariantEnum.World ? 'World' : 'Europe',
          };
          return agent.Kl.getInsuredPersonVariants(model).then((response) => {
            const person = response.find((el) => el.pesel === values.insurerPesel);
            if (
              !person?.policyPersonUbrId ||
              person?.variantId === KlVariantEnum.World ||
              person?.variantId === KlVariantEnum.EuropeNextTrip
            ) {
              modalStore.openModal(<KlBrInsuranceNotFoundInfo />, 'lg', null);
            } else {
              const orderCopy = { ...order };
              orderCopy.wasBrInsuranceVerified = true;
              orderCopy.brVerificationPesel = person.pesel;
              orderCopy.brVerificationVariant = klVariants?.find((el) => el.id === person.variantId);
              orderCopy.policyPositionId = person.policyPositionId;
              orderCopy.policyPersonUbrId = person.policyPersonUbrId;
              orderCopy.subvariantType = KlSubvariantType.BASIC;
              orderCopy.positions.forEach((position) => {
                // if (position.personType === KlPersonType.ADULT) {
                position.variantId = person.variantId;
                position.availableVariant = klVariants?.find((el) => el.id === person.variantId);
                // }
              });
              // const firstAdultIndex = orderCopy.positions.findIndex((x) => x.personType === KlPersonType.ADULT);
              // if (firstAdultIndex > -1) {
              //   orderCopy.positions[firstAdultIndex].variantId = person.variantId;
              //   orderCopy.positions[firstAdultIndex].availableVariant = klVariants?.find(
              //     (el) => el.id === person.variantId,
              //   );
              // }
              updateOrder(orderCopy);
              modalStore.openModal(<KlBrInsuranceVerifiedSuccessfullyInfo />, 'lg', null);
            }
          });
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form className='tw-flex tw-w-full tw-flex-col tw-gap-6'>
            <div className='tw-w-full tw-pb-4 '>
              <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <UserIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Podaj dane w celu
                weryfikacji
              </div>

              <div className={'tw-flex tw-flex-col tw-gap-2'}>
                <div className={'tw-flex tw-gap-4'}>
                  <TripDestinationBoxes />
                </div>
                <div className={'tw-flex tw-gap-4'}>
                  <MyTextInput
                    label={'Pesel'}
                    mask={MaskHelpers.pesel}
                    name={'insurerPesel'}
                    onChange={(event) => {
                      const value = event.target.value;
                      updateOrder({ ...order, insurerPesel: value });
                    }}
                    wrapperClassName={'tw-flex-1'}
                  />
                </div>
                <div className={'tw-flex tw-flex-col tw-gap-2'}>
                  <MyDateInput
                    label={'Data podróży od'}
                    name='startDate'
                    onChange={(date) =>
                      updateOrder({
                        ...order,
                        startDate: date?.toDateString() ?? undefined,
                      })
                    }
                    value={order.startDate}
                  />
                  <MyDateInput
                    label={'Data podróży do'}
                    name='endDate'
                    onChange={(date) =>
                      updateOrder({
                        ...order,
                        endDate: date?.toDateString() ?? undefined,
                      })
                    }
                    value={order.endDate}
                  />
                </div>
              </div>
              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'greenDark'}>
                  Weryfikuj
                </MyButton>
              </div>
            </div>

            <FormikScrollToFieldError />
          </Form>
        )}
      </Formik>
    </div>
  );
}
