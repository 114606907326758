import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useStore } from '../../store/store';
import InstitutionSelectItem from '../../components/InstitutionSelectItem/InstitutionSelectItem';
import { UserInstitution } from '../../types/user';
import { InstitutionRole } from '../../enums/roles.enums';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import { InstitutionPermission } from '../../enums/institutionPermissions.enum';
import PresentIcon from '../../components/Icons/PresentIcon';
import PiggyBankIcon from '../../components/Icons/PiggyBankIcon';

const getInstitutionDisplayTexts = (institution: UserInstitution, permissionId?: number) => {
  let header = '';
  let headerDescription = institution.userInstitutionName || '';
  let lowerDescription = '';
  let icon = <div></div>;

  switch (institution.roleId) {
    case InstitutionRole.Customer:
      header = 'Klub NAU';
      headerDescription = 'Program lojalnościowy';
      icon = (
        <PresentIcon
          className={
            'tw-absolute tw-left-1/2 tw-top-1/2  tw-z-50 tw-h-[58px] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-transform tw-text-white'
          }
        />
      );
      break;

    case InstitutionRole.SuperAdmin:
      header = 'Administrator';
      break;

    case InstitutionRole.InstitutionAdmin:
      if (permissionId === InstitutionPermission.ManageFund) {
        header = 'Fundusz';
        lowerDescription = 'Pełna kontrola nad Twoim funduszem';
        icon = (
          <PiggyBankIcon
            className={
              'tw-absolute tw-left-1/2 tw-top-1/2  tw-z-50 tw-h-[42px] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-transform tw-text-white'
            }
          />
        );
      } else if (permissionId === InstitutionPermission.ManageSettlements) {
        icon = (
          <PresentIcon
            className={
              'tw-absolute tw-left-1/2 tw-top-1/2  tw-z-50 tw-h-[58px] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-transform tw-text-white'
            }
          />
        );
        header = 'Rozliczenia';
      }
      break;

    default:
      break;
  }

  return { header, headerDescription, lowerDescription, icon };
};

const separateInstitutionsByRole = (institutions: UserInstitution[]) => {
  const customerInstitutions = institutions
    .filter((ui) => ui.roleId === InstitutionRole.Customer)
    .sort((a, b) => a.institutionId - b.institutionId);

  const adminInstitutions = institutions
    .filter((ui) => ui.roleId === InstitutionRole.SuperAdmin || ui.roleId === InstitutionRole.InstitutionAdmin)
    .sort((a, b) => a.institutionId - b.institutionId);

  return { customerInstitutions, adminInstitutions };
};

const InstitutionSelection = observer(() => {
  const { userStore } = useStore();
  const [adminInstitutions, setAdminInstitutions] = useState<UserInstitution[]>([]);
  const [individualInstitution, setIndividualInstitution] = useState<UserInstitution | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initInstitutions = async () => {
      try {
        await checkInstitutions();
      } finally {
        setLoading(false);
        userStore.setIsLoggedToInst();
      }
    };

    initInstitutions();
  }, [userStore]);

  const checkInstitutions = async () => {
    const institutions = await userStore.getUserInstitutions();

    if (institutions.length === 1) {
      await userStore.setUserInstitution(institutions[0]);
      return;
    }

    const { customerInstitutions, adminInstitutions } = separateInstitutionsByRole(institutions);

    if (adminInstitutions.length === 0 && customerInstitutions.length > 0) {
      await userStore.setUserInstitution(customerInstitutions[0]);
      return;
    }

    if (customerInstitutions.length > 0) {
      setIndividualInstitution(customerInstitutions[0]);
    }

    setAdminInstitutions(adminInstitutions);
  };

  return (
    <Container className='institutionSelect' style={{ textAlign: 'center', paddingTop: '2em' }}>
      {loading && <LoadingComponent />}

      <LogoHeader />

      <div className='tw-mt-12 tw-text-3xl tw-font-light tw-text-nau-green-dark'>Gdzie chcesz przejść?</div>

      <div className='tw-mx-auto tw-mt-10 tw-grid tw-max-w-2xl tw-grid-cols-1'>
        <div className='tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-6 tw-p-6'>
          {individualInstitution && (
            <InstitutionSelectItem
              item={individualInstitution}
              key={individualInstitution.userInstitutionId + individualInstitution.roleId}
              {...getInstitutionDisplayTexts(individualInstitution)}
            />
          )}

          {/* Render Admin institutions */}
          {adminInstitutions.map((institution) =>
            institution.userInstitutionPermissions.map((permission) => {
              return (
                <InstitutionSelectItem
                  {...getInstitutionDisplayTexts(institution, permission)}
                  item={institution}
                  key={`${institution.userInstitutionId}_${institution.roleId}_${permission}`}
                />
              );
            }),
          )}
        </div>
      </div>
    </Container>
  );
});

export default InstitutionSelection;
