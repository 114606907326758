export const PathRoute = {
  PULPIT: '/pulpit',
  ADMIN_DASHBOARD: '/admin-pulpit',
  APPROVALS: '/zgody',
  PUSTY: '/',
  CONTACT: '/kontakt',
  DELETE_ACCOUNT: '/usun-konto',
  REMEMBER_PASSWORD: '/odzyskaj-haslo',
  SERVER_ERROR: '/server-error',
  LOGIN: '/login',
  REGISTER: '/rejestracja',
  AUTHORIZE_TOKEN: '/authorizeToken',
  FUEL_CARDS: '/karty-paliwowe',
  STATION_NEAR_HOME: '/stacje-blisko-domu',
  ADD_USER: '/dodaj-uzytkownika',
  EDIT_USER: '/edytuj-uzytkownika',
  USERS: '/uzytkownicy',
  USERS_LOANS: '/wyplaty-uzytkownikow',
  CHOOSE_PLACE: '/wybor-placowki',
  VERIFY_NEW_PASSWORD: '/ustaw-nowe-haslo',
  USER_PROFILE: '/moje-dane',
  PRIVACY_POLICY: '/assets/documents/Polityka_prywatnosci_23_03_2023.pdf',
  REGULATIONS_CIRCLE_K: '/assets/documents/Regulamin Karta Paliwowa znizka na paliwo.pdf',
  REGULATIONS: '/assets/documents/Regulamin_Aplikacji_23_03_2023.pdf',
  VERIFY_EMAIL: '/weryfikacja-email',
  EMAIL_CONFIRMATION_SUCCESS: '/pomyslna-weryfikacja',
  MY_FILES: '/moje-pliki',
  LOAN: '/wypłata-od-reki',
  LOAN_APPLICATION_HISTORY: '/wypłata-od-reki-historia',
  NEWS: '/aktualnosci',
  CONFIRM_LOAN: '/potwierdzenie',
  MY_NOTIFICATIONS: '/powiadomienia',
  NEW_BENEFITS: '/twoje-benefity',
  WAY_CASH: '/potwierdzenie-wypłata-od-reki',
  DOCUMENTS_LOAN: '/dokumenty-wypłata-od-reki',
  APPROVALS_LOAN: '/zgody-wypłata-od-reki',
  VERIFY_LOAN: '/weryfikacja-wypłata-od-reki',
  PROGRESS_LOAN: '/przetwarzanie-wypłata-od-reki',
  USER_CONTRACT_LOAN: '/dane-klienta-wypłata-od-reki',
  ADDRESS_CONTRACT_LOAN: '/dane-adresowe-wypłata-od-reki',
  EMPLOYMENT_CONTRACT_LOAN: '/dane-zatrudnienia-wypłata-od-reki',
  ADDITIONAL_CONTRACT_LOAN: '/dane-dodatkowe-wypłata-od-reki',
  UPDATE_APP: 'https://play.google.com/store/apps/details?id=io.klub_nau',
  DISCOUNT_CODE: '/e-bony',
  INSTITUTIONAL_DISCOUNT_CODE: '/e-bony-od-pracodawcy',
  USER_CONTRACT_DISCOUNT_CODE: '/dane-klienta-e-bony',
  DISCOUNT_CODE_START: '/e-bony-start',
  SIGN_CONTRACT_DISCOUNT_CODE: '/umowa-e-bony',
  FILE_CONTRACT_DISCOUNT_CODE: '/plik-e-bony',
  CONFIRM_DISCOUNT_CODE_ORDER: '/potwierdzenie-zamowienia-e-bony',
  THIRTEEN_CASHMIR: 'https://13.nau.pl',
  THIRTEEN_CASHMIR_VIP: 'https://13vip.nau.pl/',
  THIRTEEN_VIP_REGULATIONS: '/Regulamin-Promocji-Trzynastka-Vip.pdf',
  LOANS_APP_LINK: 'https://pozyczki.nau.pl/#/',
  DISCOUNT_CODE_ORDER_SUCCESS: '/sukces-e-bony',
  DISCOUNT_CODE_APPROVALS: '/zgody-e-bony',
  DISCOUNT_CODE_TECHNICAL_BREAK: '/e-bony-przerwa-techniczna',
  EMPLOYMENT_STATEMENT_DISCOUNT_CODE: '/assets/documents/Wzor_zaswiadczenia_o_dochodach.pdf',
  THIRTEEN_START: '/trzynastka',
  EMPLOYMENT_CONTRACT_DISCOUNT_CODE: '/dane-zatrudnienia-e-bony',
  ADDITIONAL_CONTRACT_DISCOUNT_CODE: '/dane-dodatkowe-e-bony',
  USER_ACTIVATION: '/aktywacja-konta',
  USER_ACTIVATION_FUND: '/aktywacja-konta-fundusz',
  CAR_INSURANCE: '/komunikacja',
  BIRTHDAY_CODES: '/urodzinowe-okazje',
  BIRTHDAY_CODE_FOR_FREE: '/moje-kody-urodzinowe/gratis',
  MY_BIRTHDAY_CODES: '/moje-kody-urodzinowe',
  CAR_INSURANCE_EXTERNAL_LINK: 'https://ubezpieczenia.nau24.pl/komunikacja-ubezpieczenia/#token=8826e3d7fd733ba649a9',
  NAU_CLUB: '/klub-nau',
  BIRTHDAY_CODES_TERMS: '/assets/documents/ebony-na-urodziny.pdf',
  BIRTHDAY_CODES_TERMS_OC_TEACHER: '/E-bony-na-urodziny-OC.pdf',
  BIRTHDAY_CODES_TERMS_OC_DIRECTOR: '/E-bony-na-urodziny-Dyrektor.pdf',
  BIRTHDAY_CODES_TERMS_REFUEL: '/Regulamin-tankuj-i-zyskaj.pdf',
  PROFILES: '/profile',
  WALLET_NAU: '/portfel-nau',
  WALLET_NAU_APPROVALS: '/zgody-portfel-nau',
  USER_DATA_CONTRACT_WALLET_NAU: '/dane-klienta-portfel-nau',
  FILE_CONTRACT_WALLET_NAU: '/pliki-portfel-nau',
  CONFIRM_WALLET_PAYOUT: '/potwierdzenie-wyplaty-portfel-nau',
  WALLET_NAU_WAY_CASH: '/portfel-nau-wyplacono',
  PAYMENTS: '/platnosci',
  WALLET_NAU_WORKERS_TERMS_PDF: '/nau_workers.pdf',
  WALLET_NAU_BR_TERMS_PDF: '/nau_br.pdf',
  WALLET_NAU_ZNP_TERMS_PDF: '/nau_znp.pdf',
  WALLET_NAU_TRADE_UNIONS_TERMS_PDF: '/nau_zwiazki_zawodowe.pdf',
  WALLET_BANKS: 'https://klub.nau.pl/lista_bankow.html',
  CORDOVA_MAIN_SCREEN: '/index.html',
  ADMIN_WORKERS_IMPORT_LOAN_TEMPLATE: '/assets/szablon-importu-pracowników-wypłata-od-ręki.xlsx',
  ADMIN_WORKERS_IMPORT_TEMPLATE: '/assets/szablon-importu-pracowników.xlsx',
  LOAN_NAU: '/pozyczka-nau',
  LOAN_NAU_EXTERNAL_LINK: 'https://pozyczki.nau.pl/#/',
  PROMO_TEACHER_REGISTER: '/nauczycielvip',
  PROMO_REGISTER: '/rp',
  PROMO_REGISTER_OPZZ: '/opzz',
  PROMO_REGISTER_WITH_CODE: '/promo',
  PROMO_TEACHER_TERMS_PDF: '/Regulamin-Promocji-Nauczyciel-VIP.pdf',
  CASHBACK: '/benefit-zwrot-skladki',
  CASHBACK_USER_REGISTER: '/odzyskaj-skladke',
  CASHBACK_USER_REGISTER_2: '/zwrot-skladki',
  CASHBACK_TERMS_PDF: '/Regulamin-programu-Zwrot-Skladki.pdf',
  FUEL_CARD_OPERATOR_CHANGE: '/karta-paliwowa-nau-profit',
  SIM_NAU: '/sim-nau',
  NAU_MOBILE: '/nau-mobile',
  NAU_MOBILE_SPECIAL_OFFER: 'https://naumobile.pl/oferta_specjalna/',
  PHONE_SUBSCRIPTION: '/abonament-komorkowy',
  PHONE_SUBSCRIPTION_PROMO: '/abonament-komorkowy-promocja',
  PHONE_SUBSCRIPTION_PROMO_TERMS_PDF: '/Regulamin-Promocji-NAU-Laczy-Oswiate.pdf',

  NAU_MOBILE_SITE: 'https://naumobile.pl/',
  PAYMENT_THANK_YOU_PAGE: '/podziekowanie-po-platnosci',
  CINEMA_VOUCHER: '/kupony-do-kina',
  CINEMA_VOUCHER_DIRECTOR_PROMO: '/kupony-do-kina-dyrektor',
  CINEMA_VOUCHER_CART: '/kupony-do-kina-koszyk',
  CINEMA_VOUCHER_ORDER_SUMMARY: '/kupony-do-kina-podsumowanie',
  CINEMA_VOUCHER_ORDER_CLIENT_DATA: '/kupony-do-kina-dane-klienta',
  CINEMA_VOUCHERS_TERMS_PDF: '/assets/cinemaVouchers/Regulamin-bilety.pdf',
  CINEMA_CITY_PROGRAMME: 'https://www.cinema-city.pl/repertuar',
  HELIOS_PROGRAMME: 'https://helios.pl',
  MULTIKINO_PROGRAMME: 'https://multikino.pl/repertuar',
  TRIP_INSURANCE: '/ubezpieczenie-turystyczne',
  PROPERTY_INSURANCE: '/ubezpieczenie-majatkowe',
  PROPERTY_INSURANCE_EXTERNAL: '/ubezpieczenie-mienia',

  APP: '/app',
  APP_CINEMA_VOUCHER: '/app/kupony-do-kina',
  KL_ORDER_FORM: '/pakiet-ochrona-w-podrozy',
  KL_ORDER_FORM_APP_DASHBOARD: '/klub-nau/pakiet-ochrona-w-podrozy',
  KL_THANK_YOU_PAGE: '/podziekowanie-po-zamowieniu',
  KL_INFO_CLAUSE_DATA_PROCESSING: '/assets/kl/Klauzula-informacyjna.pdf',
  KL_MANDATORY_CONSENTS: '/assets/kl/Obligatoryjne-zgody-Towarzytwa-Ubezpieczeniowego.pdf',
  CASHBACK_NNW_KIDS: '/nnw-dzieci-zwrot-skladki',
  CINEMA_VOUCHERS_PROMOTION_SCHOOL_DIRECTOR: '/kina',
  CINEMA_VOUCHERS_PROMOTION_SCHOOL_DIRECTOR_TEACHERS_DAY: '/kina-dzien-nauczyciela',
  CINEMA_VOUCHERS_PROMOTION_SCHOOL_STUFF: '/kina-pracownik',
  CINEMA_VOUCHER_PROMO_DIRECTOR_ORDER_SUMMARY: '/kupony-do-kina-promocja-podsumowanie',
  CINEMA_VOUCHER_PROMO_NAU_ORDER_SUMMARY: '/kupony-do-kina-promocja-nau-podsumowanie',
  CINEMA_VOUCHER_PROMO_DIRECTOR_EXPIRED: '/kupony-do-kina-promocja-wygasla',
  CINEMA_VOUCHER_PROMO_DIRECTOR_LINK_EXPIRED: '/kupony-do-kina-link_nieaktualny',
  CINEMA_VOUCHER_PROMO_DIRECTOR_BAD_DATA: '/kupony-do-kina-promocja-bledne-dane',
  CASHBACK_NNW_2024_REFUND_PROMO: '/nnw-2024-zwrot-skladki-bonus',
  SPORT_CARD: '/benefit-karta-sportowa',
  SPORT_CARD_DETAILS: '/benefit-karta-sportowa-szczegoly',
  SPORT_CARD_PZU_REGULATIONS: '/assets/sportCard/Zasady_korzystania_z_PZU_Sport.pdf',
  SPORT_CARD_PZU_RODO: '/assets/sportCard/Informacja_Administratora_Danych_Osobowych.pdf',
  SPORT_CARD_DEDUCTION_REQUEST_FILE: '/assets/sportCard/WNIOSEK O DOKONYWANIE POTRĄCEŃ.pdf',

  INSTITUTION_ADMIN_DASHBOARD: '/panel-administracyjny-placowki',
  INSTITUTION_ADMIN_EMPLOYEES: '/panel-administracyjny-placowki/pracownicy',
  INSTITUTION_ADMIN_SETTLEMENTS: '/panel-administracyjny-placowki/rozliczenia',
  INSTITUTION_ADMIN_FUND: '/panel-administracyjny-placowki/fundusz',
  INSTITUTION_ADMIN_FUND_REGULATIONS_FILE: '/assets/institutionAdmin/Regulamin funduszu.pdf',
  INSTITUTION_ADMIN_SPORT_CRADS: '/panel-administracyjny-placowki/karty-sportowa',
  INSTITUTION_ADMIN_LOAN: '/panel-administracyjny-placowki/wyplata-od-reki',
} as const;
