import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

interface IKlPreviousStepButtonProps {
  onClick: () => void;
}
export default function KlPreviousStepButton(props: IKlPreviousStepButtonProps) {
  return (
    <div
      className={
        'tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-2 tw-font-semibold tw-text-nau-kids-gray tw-transition-all hover:tw-scale-105'
      }
      onClick={() => props.onClick()}>
      <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
      Poprzedni krok
    </div>
  );
}
