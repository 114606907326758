export const calculateAgeFromPesel = (pesel: string): number => {
  pesel = pesel.replaceAll('_', '');
  if (pesel.length !== 11) {
    return 0;
  }
  const year = parseInt(pesel.substring(0, 2), 10);
  const month = parseInt(pesel.substring(2, 4), 10);
  const day = parseInt(pesel.substring(4, 6), 10);

  let fullYear = 1900 + year;
  if (month > 20) {
    fullYear += 100;
  }

  const birthDate = new Date(fullYear, (month % 20) - 1, day);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
