import React from 'react';
import { twMerge } from '../../index';

interface StepIndicatorProps {
  steps: string[];
  currentStep: number;
}

const TextStepIndicator: React.FC<StepIndicatorProps> = ({ steps, currentStep }) => {
  return (
    <div className='tw-my-4 tw-flex tw-items-center tw-gap-4'>
      {steps.map((step, index) => (
        <div className='tw-flex tw-items-center tw-gap-2' key={index}>
          <div
            className={`
               tw-hidden tw-h-8 tw-w-8 tw-flex-none tw-items-center tw-justify-center tw-rounded-full
              tw-text-sm tw-font-medium sm:tw-flex 
              ${index + 1 === currentStep ? 'tw-bg-nau-green-light tw-text-white' : 'tw-bg-gray-200 tw-text-gray-600'}
            `}>
            {index + 1}
          </div>
          <span
            className={twMerge(`
              tw-text-xs tw-font-medium 
              ${index + 1 === currentStep ? 'tw-font-semibold tw-text-nau-kids-gray' : 'tw-text-gray-600'}
            `)}>
            {step}
          </span>
          {index < steps.length - 1 && <div className='tw-h-px tw-w-1 tw-bg-gray-300 sm:tw-w-10'></div>}
        </div>
      ))}
    </div>
  );
};

export default TextStepIndicator;
