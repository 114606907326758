import { KlOrderStep, useKlStore } from '../../../store/klStore/klZustandStore';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import React, { useEffect, useRef, useState } from 'react';
import { KlOrderFormM } from '../../../types/kl';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { FormikScrollToFieldError } from '../../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import * as Yup from 'yup';
import _ from 'lodash';
import { KlInsuranceProductInformation } from '../KlInsuranceProductInformation';
import KlPreviousStepButton from './KlPreviousStepButton';

interface IKlApprovalsFormProps {
  afterSave?: () => void;
}
export default function KlApprovalsForm(props: IKlApprovalsFormProps) {
  const { order, updateOrder, setStep } = useKlStore();

  const { data: klVariants, isLoading: isLoadingKlVariants } = useSwrAgent().Kl.GetAllVariants();

  const validationSchema = Yup.object({
    approvalBonVoyage1: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalBonVoyage2: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalBonVoyage3: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalBonVoyage4: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalInterrisk1: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalInterrisk2: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalInterrisk3: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalInterrisk4: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalInterrisk5: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
    approvalMarketing1: Yup.boolean().oneOf([true], 'Oświadczenie jest wymagane'),
    approvalMarketing2: Yup.boolean().oneOf([true], 'Oświadczenie jest wymagane'),
  });

  const [selectAllApprovalsState, setSelectAllApprovalsState] = useState<'all' | 'some' | 'none'>('none');
  const selectAllCheckboxRef = useRef<HTMLInputElement>(null);

  const handleApprovalChange = (name: keyof KlOrderFormM, isChecked: boolean) => {
    const newOrder = _.cloneDeep(order);
    newOrder[name] = isChecked;

    let approvalsCount = 0;
    let approvalsCheckedCount = 0;

    Object.keys(newOrder).forEach((key) => {
      if (key.startsWith('approval')) {
        approvalsCount++;
        if (newOrder[key as keyof KlOrderFormM]) {
          approvalsCheckedCount++;
        }
      }
    });
    if (approvalsCount === approvalsCheckedCount) {
      setSelectAllApprovalsState('all');
    } else if (approvalsCheckedCount === 0) {
      setSelectAllApprovalsState('none');
    } else {
      setSelectAllApprovalsState('some');
    }
    updateOrder(newOrder);
  };

  const handleSelectAllApprovals = () => {
    const newOrder = _.cloneDeep(order);
    Object.keys(newOrder).forEach((key) => {
      if (key.startsWith('approval')) {
        newOrder[key as keyof KlOrderFormM] = true;
      }
    });
    updateOrder(newOrder);
    setSelectAllApprovalsState('all');
  };

  const handleDeselectAllApprovals = () => {
    const newOrder = _.cloneDeep(order);
    Object.keys(newOrder).forEach((key) => {
      if (key.startsWith('approval')) {
        newOrder[key as keyof KlOrderFormM] = false;
      }
    });
    updateOrder(newOrder);
    setSelectAllApprovalsState('none');
  };

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      selectAllCheckboxRef.current.indeterminate = selectAllApprovalsState === 'some';
    }
  }, [selectAllApprovalsState]);

  return (
    <div className=''>
      {isLoadingKlVariants && <LoadingComponent />}
      <Formik
        enableReinitialize={true}
        initialValues={order}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<KlOrderFormM>) => {
          props?.afterSave?.();
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form className='tw-flex tw-w-full tw-flex-col tw-gap-6'>
            <KlPreviousStepButton onClick={() => setStep(KlOrderStep.Step1)} />
            <div className={'tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '}>
              <div className={'tw-pb-8 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <DocumentIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Informacje o produkcie
                ubezpieczeniowym
              </div>

              <KlInsuranceProductInformation />
            </div>
            <div className='tw-w-full tw-rounded-lg tw-bg-white  tw-px-2 tw-py-6 sm:tw-px-6 '>
              <div className={'tw-mb-6 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>
                <DocumentIcon className=' tw-inline-block tw-h-6 tw-w-6 tw-text-nau-kids-gray' /> Zgody i oświadczenia
              </div>

              <div className={'tw-flex tw-items-center tw-gap-2 tw-pt-6'}>
                <input
                  checked={selectAllApprovalsState === 'all'}
                  className={
                    'cursor-pointer focus:ring-transparent tw-h-6 tw-w-6 tw-rounded tw-border-2 tw-border-nau-green-dark tw-text-nau-green-dark tw-ring-0 focus:tw-outline-0 focus:tw-ring-0 focus:tw-ring-transparent focus:tw-ring-offset-0'
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleSelectAllApprovals();
                    } else {
                      handleDeselectAllApprovals();
                    }
                  }}
                  ref={selectAllCheckboxRef}
                  type='checkbox'
                />
                <span className={'tw-text-sm'}>Zaznacz wszystkie zgody</span>
              </div>
              <div className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 1</div>
              <p className={'tw-text-bold tw-text-sm tw-leading-6'}>
                <b>
                  Zgoda na przystąpienie do grupowego ubezpieczenia kosztów leczenia za granicą Bon Voyage w ramach
                  Programu Bezpieczna Rodzina
                </b>
              </p>
              <br />
              <MyCheckbox
                checked={values.approvalBonVoyage1}
                className={'tw-text-nau-green-dark'}
                label={`Wyrażam zgodę na przystąpienie do grupowego ubezpieczenia kosztów leczenia za granicą Bon Voyage w ramach Programu Bezpieczna Rodzina (BR) zawartego przez NAU Profit Sp. z o.o. z siedzibą w Warszawie, przy ul. Smulikowskiego 6/8 jako ubezpieczającym, z InterRisk Towarzystwem Ubezpieczeń S.A. Vienna Insurance Group z siedzibą w Warszawie przy ul. Noakowskiego 22 (zwane dalej InterRisk).`}
                labelClassName={'tw-text-xs'}
                name={`approvalBonVoyage1`}
                onChange={(event) => {
                  handleApprovalChange('approvalBonVoyage1', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 2</h6>

              <MyCheckbox
                checked={values.approvalBonVoyage2}
                className={'tw-text-nau-green-dark'}
                label={
                  'Jednocześnie oświadczam, iż przed wyrażeniem zgody na objęcie mnie ochroną ubezpieczeniową i opłacenie składki ubezpieczeniowej otrzymałem/am Ogólne Warunki Ubezpieczenia kosztów leczenia za granicą Bon Voyage zatwierdzone uchwałą nr 05/06/07/2021 Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 06.07.2021r. (zwane dalej OWU) oraz postanowienia dodatkowe i odmienne od niniejszych OWU, informację o której mowa w art.17 ustawy o działalności ubezpieczeniowej i reasekuracyjnej, informację dotyczącą możliwości i procedury złożenia i rozpatrzenia skarg i reklamacji, organu właściwego do ich rozpatrzenia oraz pozasądowego rozpatrywania sporów i je akceptuję.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalBonVoyage2`}
                onChange={(event) => {
                  setFieldValue('approvalBonVoyage2', event.target.checked);
                  handleApprovalChange('approvalBonVoyage2', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 3</h6>

              <MyCheckbox
                checked={values.approvalBonVoyage3}
                className={'tw-text-nau-green-dark'}
                label={
                  'Oświadczam, że otrzymałem ustandaryzowany dokument zawierający informacje o produkcie ubezpieczeniowym i jestem świadomy/a, że dokument ten ma charakter informacyjny, a pełne informacje o produkcie ubezpieczeniowym zawarte są w Ogólnych Warunkach Ubezpieczenia.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalBonVoyage3`}
                onChange={(event) => {
                  handleApprovalChange('approvalBonVoyage3', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 4</h6>
              <MyCheckbox
                checked={values.approvalBonVoyage4}
                className={'tw-text-nau-green-dark'}
                label={
                  'Oświadczam, że otrzymałem/am klauzule informacyjne o przetwarzaniu danych osobowych zgodnie z RODO (Rozporządzeniem o Ochronie Danych Osobowych) w NAU Profit Sp. z o.o., NAU S.A. oraz InterRisk Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalBonVoyage4`}
                onChange={(event) => {
                  handleApprovalChange('approvalBonVoyage4', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 5</h6>
              <MyCheckbox
                checked={values.approvalInterrisk1}
                className={'tw-text-nau-green-dark'}
                label={
                  'Wyrażam zgodę, iż moje dane osobowe oraz dane osobowe wskazanych przeze mnie członków rodziny mogą być przekazywane do InterRisk Towarzystwa Ubezpieczeń S.A. Vienna Insurance Group w celu zawarcia i wykonywania grupowej umowy ubezpieczenia kosztów leczenia za granicą Bon Voyage w ramach programu „Bezpieczna Rodzina”.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalInterrisk1`}
                onChange={(event) => {
                  handleApprovalChange('approvalInterrisk1', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 6</h6>
              <MyCheckbox
                checked={values.approvalInterrisk2}
                className={'tw-text-nau-green-dark'}
                label={
                  'Wyrażam zgodę na przetwarzanie przez InterRisk TU S.A. Vienna Insurance Group moich danych osobowych o stanie zdrowia w sposób zautomatyzowany w ramach profilowania klientów przed zawarciem umowy ubezpieczenia na potrzeby oceny ryzyka ubezpieczeniowego oraz na ich przetwarzanie w celu wykonania umowy ubezpieczenia.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalInterrisk2`}
                onChange={(event) => {
                  handleApprovalChange('approvalInterrisk2', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 7</h6>
              <MyCheckbox
                checked={values.approvalInterrisk3}
                className={'tw-text-nau-green-dark'}
                label={
                  'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od podmiotów wykonujących działalność leczniczą w rozumieniu powszechnie obowiązujących przepisów, które udzielały lub będą udzielać mi świadczeń zdrowotnych, informacji o okolicznościach związanych z oceną ryzyka ubezpieczeniowego i weryfikacją danych przeze mnie danych o stanie mojego zdrowia, ustaleniem prawa do świadczenia z zawartej umowy ubezpieczenia i wysokością tego świadczenia, w zakresie określonym w ustawie o działalności ubezpieczeniowej i reasekuracyjnej. Równocześnie wyrażam zgodę na udostępnienie InterRisk TU S.A. Vienna Insurance Group przez wyżej wymienione podmioty dokumentacji medycznej.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalInterrisk3`}
                onChange={(event) => {
                  handleApprovalChange('approvalInterrisk3', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 8</h6>
              <MyCheckbox
                checked={values.approvalInterrisk4}
                className={'tw-text-nau-green-dark'}
                label={
                  'Wyrażam zgodę na udostępnianie - na żądanie InterRisk TU S.A. Vienna Insurance Group - moich danych osobowych przetwarzanych przez inne zakłady ubezpieczeń w zakresie potrzebnym do oceny ryzyka ubezpieczeniowego i weryfikacji podanych przeze mnie danych, ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia, a także do udzielania posiadanych informacji o przyczynie mojej śmierci lub informacji niezbędnych do ustalenia prawa uprawnionego z umowy ubezpieczenia do świadczenia i jego wysokości.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalInterrisk4`}
                onChange={(event) => {
                  handleApprovalChange('approvalInterrisk4', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 9</h6>
              <MyCheckbox
                checked={values.approvalInterrisk5}
                className={'tw-text-nau-green-dark'}
                label={
                  'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od Narodowego Funduszu Zdrowia o informacjach o nazwach i adresach świadczeniodawców, którzy udzielili mi świadczeń opieki zdrowotnej w związku z wypadkiem lub zdarzeniem losowym będącym podstawą ustalenia odpowiedzialności InterRisk TU S.A. Vienna Insurance Group oraz wysokości odszkodowania lub świadczenia.'
                }
                labelClassName={'tw-text-xs'}
                name={`approvalInterrisk5`}
                onChange={(event) => {
                  handleApprovalChange('approvalInterrisk5', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 10</h6>
              <MyCheckbox
                checked={values.approvalMarketing1}
                className={'tw-text-nau-green-dark'}
                label={'Dystrybutor ubezpieczeń przeprowadził analizę wymagań i potrzeb ubezpieczającego'}
                labelClassName={'tw-text-xs'}
                moreInfoText={
                  'Dystrybutor ubezpieczeń (tj. NAU) przed zawarciem umowy ubezpieczenia grupowego w ramach Programu BR, do której chcę przystąpić, przeprowadził analizę wymagań i potrzeb ubezpieczającego (tj. NAU Profit Sp. z o.o. ) w zakresie ochrony ubezpieczeniowej w ramach ubezpieczenia Odpowiedzialności Cywilnej w Życiu Prywatnym, Odpowiedzialności Cywilnej i Materialnej Nauczycieli oraz Pozostałych Pracowników Szkoły lub Odpowiedzialności Cywilnej Dyrektora Placówki Oświatowej (Zastępcy Dyrektora oraz Osoby Pełniącej Obowiązki Dyrektora)'
                }
                name={`approvalMarketing1`}
                onChange={(event) => {
                  handleApprovalChange('approvalMarketing1', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 11</h6>
              <MyCheckbox
                checked={values.approvalMarketing2}
                className={'tw-text-nau-green-dark'}
                label={'Dystrybutor ubezpieczeń przeprowadził analizę wymagań i potrzeb ubezpieczającego'}
                labelClassName={'tw-text-xs'}
                moreInfoText={
                  'Dystrybutor ubezpieczeń (tj. NAU) przed zawarciem umowy ubezpieczenia grupowego w ramach Programu BR, do którego chcę przystąpić, przeprowadził analizę wymagań i potrzeb Ubezpieczającego (tj. NAU Profit Sp. z o.o. ) w zakresie ochrony ubezpieczeniowej w ramach ubezpieczenia mienia.'
                }
                name={`approvalMarketing2`}
                onChange={(event) => {
                  handleApprovalChange('approvalMarketing2', event.target.checked);
                }}
                variant={'big'}
              />

              <h6 className={'tw-mb-2 tw-mt-6 tw-text-sm'}>Zgoda nr 12</h6>
              <p className={'tw-text-bold tw-text-sm tw-leading-6'}>
                <b>
                  Dzięki tej zgodzie będziemy mogli przesłać Panu/Pani, za pomocą wiadomości elektronicznej lub
                  połączenia głosowego, i udzielać niezbędnych informacji dotyczących ochrony ubezpieczeniowej w ramach
                  programu Bezpieczna Rodzina oraz przedstawiać ofertę za pomocą wiadomości elektronicznej lub
                  połączenia głosowego.
                </b>
              </p>

              <br />
              <MyCheckbox
                checked={values.approvalMarketingEmail}
                className={'tw-text-nau-green-dark'}
                label={`wiadomości elektroniczne e-mail`}
                labelClassName={'tw-text-xs'}
                name={`approvalMarketingEmail`}
                onChange={(event) => {
                  handleApprovalChange('approvalMarketingEmail', event.target.checked);
                }}
                variant={'big'}
              />
              <br />
              <MyCheckbox
                checked={values.approvalMarketingSms}
                className={'tw-text-nau-green-dark'}
                label={`wiadomości elektroniczne SMS/MMS`}
                labelClassName={'tw-text-xs'}
                name={`approvalMarketingSms`}
                onChange={(event) => {
                  handleApprovalChange('approvalMarketingSms', event.target.checked);
                }}
                variant={'big'}
              />
              <br />
              <MyCheckbox
                checked={values.approvalMarketingPhone}
                className={'tw-text-nau-green-dark'}
                label={`połączenia głosowe (rozmowa telefoniczna)`}
                labelClassName={'tw-text-xs'}
                name={`approvalMarketingPhone`}
                onChange={(event) => {
                  handleApprovalChange('approvalMarketingPhone', event.target.checked);
                }}
                variant={'big'}
              />
              <br />
              <MyCheckbox
                checked={values.approvalMarketingIvr}
                className={'tw-text-nau-green-dark'}
                label={`połączenia głosowe (komunikaty głosowe IVR)`}
                labelClassName={'tw-text-xs'}
                name={`approvalMarketingIvr`}
                onChange={(event) => {
                  handleApprovalChange('approvalMarketingIvr', event.target.checked);
                }}
                variant={'big'}
              />

              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'greenDark'}>
                  Dalej
                </MyButton>
              </div>
            </div>

            <FormikScrollToFieldError />
          </Form>
        )}
      </Formik>
    </div>
  );
}
