export function mod97Check(numericString: string): boolean {
  const chunkSize = 9;
  let remainder = 0n;

  for (let i = 0; i < numericString.length; i += chunkSize) {
    const chunk = remainder.toString() + numericString.substring(i, i + chunkSize);
    remainder = BigInt(chunk) % 97n;
  }

  return remainder === 1n;
}

/**
 * Validates a Polish bank account number (IBAN-style) by:
 * 1) Checking for exactly 26 digits
 * 2) Prepending "PL"
 * 3) Rearranging first 4 characters to the end
 * 4) Replacing letters [A-Z] with numbers [10-35]
 * 5) Performing the mod-97 check
 */
export function isValidPolishIban(value: string | undefined | null): boolean {
  if (!value) {
    return false;
  }

  // Remove all spaces
  const digitsOnly = value.replace(/\s+/g, '');

  // Must be exactly 26 digits
  if (!/^\d{26}$/.test(digitsOnly)) {
    return false;
  }

  // Prepend "PL"
  const ibanCandidate = 'PL' + digitsOnly;

  // Move the first 4 chars to the end
  const rearranged = ibanCandidate.slice(4) + ibanCandidate.slice(0, 4);

  // Convert letters [A-Z] to numbers [10-35], digits remain digits
  let numericString = '';
  for (const char of rearranged) {
    if (/[A-Z]/.test(char)) {
      // 'A' => 10, 'B' => 11, ...
      numericString += (char.charCodeAt(0) - 55).toString();
    } else if (/\d/.test(char)) {
      numericString += char;
    } else {
      // Invalid character
      return false;
    }
  }

  // Final mod-97 check
  return mod97Check(numericString);
}
